import React from 'react';

const Polypropylene6 = () => {
  return (
    <svg width='89' height='89' viewBox='0 0 89 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2911_969)'>
        <path
          d='M23.2028 53.8924L15.5642 62.6023H61.7354L72.7882 49.9991H65.7332V48.7188H75.6015L62.3102 63.8827H12.7422L21.4957 53.8924H23.2028Z'
          fill='#004737' />
        <path d='M14.153 62.6025H20.7725V67.7675H14.153V66.4872H19.4921V63.8829H14.153V62.6025Z' fill='#004737' />
        <path
          d='M20.128 62.6025H26.7562V67.7675H19.4922V62.6025H20.128ZM25.4759 63.8829H20.7725V66.4872H25.4759V63.8829Z'
          fill='#004737' />
        <path
          d='M26.1124 62.6025H32.7406V67.7675H25.4765V62.6025H26.1124ZM31.4602 63.8829H26.7569V66.4872H31.4602V63.8829Z'
          fill='#004737' />
        <path
          d='M32.0958 62.6025H38.7241V67.7675H31.46V62.6025H32.0958ZM37.4437 63.8829H32.7404V66.4872H37.4437V63.8829Z'
          fill='#004737' />
        <path
          d='M38.0794 62.6025H44.6989V67.7675H37.4435V62.6025H38.0794ZM43.4185 63.8829H38.7239V66.4872H43.4185V63.8829Z'
          fill='#004737' />
        <path
          d='M44.0542 62.6025H50.6825V67.7675H43.4184V62.6025H44.0542ZM49.4021 63.8829H44.6988V66.4872H49.4021V63.8829Z'
          fill='#004737' />
        <path
          d='M50.0378 62.6025H56.666V67.7675H49.4019V62.6025H50.0378ZM55.3856 63.8829H50.6823V66.4872H55.3856V63.8829Z'
          fill='#004737' />
        <path d='M62.0058 67.7675H55.3863V62.6025H62.0058V63.8829H56.6666V66.4872H62.0058V67.7675Z' fill='#004737' />
        <path
          d='M18.0202 63.7082L15.5988 66.4431H61.7874L72.9187 53.8311H70.7847V52.5508H75.7494L62.3448 67.7234H12.7681L17.0621 62.8633L18.0202 63.7082Z'
          fill='#004737' />
        <path d='M55.778 66.4078L58.9484 62.8193L59.9065 63.6642L56.7361 67.2527L55.778 66.4078Z' fill='#004737' />
        <path d='M49.7679 66.4078L52.947 62.8193L53.8964 63.6642L50.726 67.2527L49.7679 66.4078Z' fill='#004737' />
        <path d='M43.7234 66.4078L46.9025 62.8193L47.8519 63.6642L44.6815 67.2527L43.7234 66.4078Z' fill='#004737' />
        <path d='M37.8439 66.4078L41.023 62.8193L41.9811 63.6642L38.802 67.2527L37.8439 66.4078Z' fill='#004737' />
        <path d='M31.8432 66.4078L35.0136 62.8193L35.9717 63.6642L32.7925 67.2527L31.8432 66.4078Z' fill='#004737' />
        <path d='M25.9026 66.4078L29.0817 62.8193L30.0311 63.6642L26.8607 67.2527L25.9026 66.4078Z' fill='#004737' />
        <path d='M19.9801 66.4078L23.1505 62.8193L24.1086 63.6642L20.9382 67.2527L19.9801 66.4078Z' fill='#004737' />
        <path
          d='M23.4033 39.7305L15.5644 48.6669H61.7355L72.7884 36.0723H65.7334V34.792H75.6017L62.3104 49.9559H12.7424L21.6961 39.7305H23.4033Z'
          fill='#004737' />
        <path d='M14.153 48.667H20.7725V53.8407H14.153V52.5603H19.4921V49.9561H14.153V48.667Z' fill='#004737' />
        <path d='M20.128 48.667H26.7562V53.8407H19.4922V48.667H20.128ZM25.4759 49.9561H20.7725V52.5603H25.4759V49.9561Z'
              fill='#004737' />
        <path
          d='M26.1124 48.667H32.7406V53.8407H25.4765V48.667H26.1124ZM31.4602 49.9561H26.7569V52.5603H31.4602V49.9561Z'
          fill='#004737' />
        <path d='M32.0958 48.667H38.7241V53.8407H31.46V48.667H32.0958ZM37.4437 49.9561H32.7404V52.5603H37.4437V49.9561Z'
              fill='#004737' />
        <path
          d='M38.0794 48.667H44.6989V53.8407H37.4435V48.667H38.0794ZM43.4185 49.9561H38.7239V52.5603H43.4185V49.9561Z'
          fill='#004737' />
        <path
          d='M44.0543 48.667H50.6825V53.8407H43.4185V48.667H44.0543ZM49.4022 49.9561H44.6988V52.5603H49.4022V49.9561Z'
          fill='#004737' />
        <path
          d='M50.0378 48.667H56.666V53.8407H49.4019V48.667H50.0378ZM55.3856 49.9561H50.6823V52.5603H55.3856V49.9561Z'
          fill='#004737' />
        <path d='M62.0058 53.8407H55.3863V48.667H62.0058V49.9561H56.6666V52.5603H62.0058V53.8407Z' fill='#004737' />
        <path
          d='M18.0202 49.7814L15.5989 52.5163H61.7874L72.9187 39.9044H70.7848V38.624H75.7494L62.3449 53.7967H12.7682L17.0621 48.9365L18.0202 49.7814Z'
          fill='#004737' />
        <path d='M55.778 52.4723L58.9484 48.8926L59.9065 49.7287L56.7361 53.3259L55.778 52.4723Z' fill='#004737' />
        <path d='M49.7679 52.4723L52.947 48.8926L53.8964 49.7287L50.726 53.3259L49.7679 52.4723Z' fill='#004737' />
        <path d='M43.7234 52.4723L46.9025 48.8926L47.8519 49.7287L44.6815 53.3259L43.7234 52.4723Z' fill='#004737' />
        <path d='M37.8439 52.4723L41.023 48.8926L41.9811 49.7287L38.802 53.3259L37.8439 52.4723Z' fill='#004737' />
        <path d='M31.8432 52.4723L35.0136 48.8926L35.9717 49.7287L32.7925 53.3259L31.8432 52.4723Z' fill='#004737' />
        <path d='M25.9026 52.4723L29.0818 48.8926L30.0311 49.7287L26.8607 53.3259L25.9026 52.4723Z' fill='#004737' />
        <path d='M19.9801 52.4723L23.1505 48.8926L24.1086 49.7287L20.9382 53.3259L19.9801 52.4723Z' fill='#004737' />
        <path
          d='M26.6085 22.1876L15.5644 34.7908H61.7355L72.7884 22.1876C57.398 22.1876 41.9989 22.1876 26.6085 22.1876ZM75.6017 20.9072L62.3104 36.0712H12.7424L26.0337 20.9072C42.5563 20.9072 59.079 20.9072 75.6017 20.9072Z'
          fill='#004737' />
        <path d='M14.153 34.791H20.7725V39.956H14.153V38.6756H19.4921V36.0714H14.153V34.791Z' fill='#004737' />
        <path d='M20.128 34.791H26.7562V39.956H19.4922V34.791H20.128ZM25.4759 36.0714H20.7725V38.6756H25.4759V36.0714Z'
              fill='#004737' />
        <path
          d='M26.1124 34.791H32.7406V39.956H25.4765V34.791H26.1124ZM31.4602 36.0714H26.7569V38.6756H31.4602V36.0714Z'
          fill='#004737' />
        <path d='M32.0958 34.791H38.7241V39.956H31.46V34.791H32.0958ZM37.4437 36.0714H32.7404V38.6756H37.4437V36.0714Z'
              fill='#004737' />
        <path
          d='M38.0794 34.791H44.6989V39.956H37.4435V34.791H38.0794ZM43.4185 36.0714H38.7239V38.6756H43.4185V36.0714Z'
          fill='#004737' />
        <path
          d='M44.0543 34.791H50.6825V39.956H43.4185V34.791H44.0543ZM49.4022 36.0714H44.6988V38.6756H49.4022V36.0714Z'
          fill='#004737' />
        <path d='M50.0378 34.791H56.666V39.956H49.4019V34.791H50.0378ZM55.3856 36.0714H50.6823V38.6756H55.3856V36.0714Z'
              fill='#004737' />
        <path d='M62.0058 39.956H55.3863V34.791H62.0058V36.0714H56.6666V38.6756H62.0058V39.956Z' fill='#004737' />
        <path
          d='M18.0202 35.8966L15.5989 38.6315H61.7874L72.9187 26.0196H70.7848V24.7393H75.7494L62.3449 39.9119H12.7682L17.0621 35.0518L18.0202 35.8966Z'
          fill='#004737' />
        <path d='M55.778 38.5963L58.9484 35.0078L59.9065 35.8527L56.7361 39.4411L55.778 38.5963Z' fill='#004737' />
        <path d='M49.7679 38.5963L52.947 35.0078L53.8964 35.8527L50.726 39.4411L49.7679 38.5963Z' fill='#004737' />
        <path d='M43.7234 38.5963L46.9025 35.0078L47.8519 35.8527L44.6815 39.4411L43.7234 38.5963Z' fill='#004737' />
        <path d='M37.8439 38.5963L41.023 35.0078L41.9811 35.8527L38.802 39.4411L37.8439 38.5963Z' fill='#004737' />
        <path d='M31.8432 38.5963L35.0136 35.0078L35.9717 35.8527L32.7925 39.4411L31.8432 38.5963Z' fill='#004737' />
        <path d='M25.9026 38.5963L29.0818 35.0078L30.0311 35.8527L26.8607 39.4411L25.9026 38.5963Z' fill='#004737' />
        <path d='M19.9801 38.5963L23.1505 35.0078L24.1086 35.8527L20.9382 39.4411L19.9801 38.5963Z' fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.2977 1.22461C68.0408 1.22461 87.3594 20.5432 87.3594 44.3908C87.3594 68.134 68.0408 87.4526 44.2977 87.4526C20.45 87.4526 1.13144 68.134 1.13144 44.3908C1.13144 20.5432 20.45 1.22461 44.2977 1.22461Z'
              stroke='#004737' strokeWidth='1.75028' />
      </g>
      <defs>
        <clipPath id='clip0_2911_969'>
          <rect width='87.9699' height='87.9699' fill='white' transform='translate(0.260345 0.353516)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Polypropylene6;
