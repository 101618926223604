import React from 'react';

const PhoneSvg = () => {
  return (
    <>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6284 0.573187C10.6542 0.474394 10.6995 0.381754 10.7616 0.300693C10.8237 0.219633 10.9014 0.151782 10.9901 0.101117C11.0787 0.0504525 11.1766 0.0179917 11.278 0.00563721C11.3794 -0.0067173 11.4822 0.00128287 11.5804 0.0291688C13.0292 0.417727 14.3502 1.18063 15.4108 2.24125C16.4714 3.30188 17.2343 4.6229 17.6229 6.07165C17.6508 6.16989 17.6588 6.27271 17.6464 6.37407C17.6341 6.47544 17.6016 6.57333 17.5509 6.66199C17.5003 6.75065 17.4324 6.82831 17.3514 6.89042C17.2703 6.95253 17.1777 6.99784 17.0789 7.02368C17.0126 7.04289 16.9439 7.0527 16.8749 7.05283C16.7033 7.05184 16.537 6.99414 16.4016 6.8887C16.2663 6.78327 16.1697 6.63604 16.1268 6.46995C15.8037 5.28699 15.1776 4.20874 14.3105 3.3416C13.4433 2.47446 12.3651 1.84841 11.1821 1.52522C11.0824 1.50043 10.9888 1.4559 10.9066 1.39424C10.8245 1.33259 10.7555 1.25508 10.7039 1.1663C10.6523 1.07752 10.619 0.979284 10.606 0.877403C10.593 0.775523 10.6006 0.672074 10.6284 0.573187ZM10.3758 4.52703C11.0334 4.70703 11.6327 5.05521 12.1148 5.53729C12.5968 6.01936 12.945 6.6187 13.125 7.27626C13.1664 7.44318 13.2626 7.59137 13.3982 7.69706C13.5339 7.80276 13.7011 7.85983 13.8731 7.85914C13.939 7.85979 14.0045 7.84995 14.0673 7.83C14.167 7.80521 14.2607 7.76068 14.3428 7.69902C14.425 7.63737 14.4939 7.55986 14.5455 7.47108C14.5972 7.3823 14.6305 7.28406 14.6434 7.18218C14.6564 7.0803 14.6488 6.97685 14.6211 6.87797C14.3757 5.9546 13.8907 5.11248 13.2151 4.43691C12.5396 3.76134 11.6975 3.27632 10.7741 3.03098C10.6727 2.99405 10.5648 2.97863 10.4571 2.98571C10.3494 2.99278 10.2444 3.0222 10.1488 3.07208C10.0531 3.12196 9.96885 3.19122 9.90142 3.27544C9.83398 3.35966 9.78482 3.457 9.75706 3.56126C9.7293 3.66552 9.72355 3.77442 9.74019 3.88102C9.75682 3.98763 9.79547 4.0896 9.85368 4.18044C9.91188 4.27129 9.98836 4.34903 10.0783 4.4087C10.1681 4.46837 10.2695 4.50867 10.3758 4.52703Z"
          fill="#0E6B56"
        />
        <path
          d="M3.93799 0.251953C2.24999 0.251953 0 2.50194 0 4.18994C0 6.36394 2.25 10.377 4.5 12.627C4.742 12.869 5.383 13.51 5.625 13.752C7.875 16.002 11.888 18.252 14.062 18.252C15.75 18.252 18 16.002 18 14.314C18 12.627 15.75 10.377 14.062 10.377C13.5 10.377 11.465 11.541 11.25 11.502C9.456 11.178 7.125 8.78895 6.75 7.00195C6.698 6.75295 7.875 4.75194 7.875 4.18994C7.875 2.50194 5.62499 0.251953 3.93799 0.251953Z"
          fill="#0E6B56"
        />
      </svg>
    </>
  );
};

export default PhoneSvg;
