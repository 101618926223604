import work1 from '../../../assets/images/work1.webp';
import work2 from '../../../assets/images/work2.webp';
import work3 from '../../../assets/images/work3.webp';
import work4 from '../../../assets/images/work4.webp';
import work5 from '../../../assets/images/work5.webp';
import work6 from '../../../assets/images/work6.webp';

const worksData = [
  {
    image: work1,
    text: 'ОЧИСТНОЕ СООРУЖЕНИЕ ДЛЯ ДОМА КУЛЬТУРЫ  ROYAL GATE',
      desc: 'Инкубационно мальковый цех.',
    perf: '10 000 000 тыс шт рыбы сиг и 800 000 тыс шт для радужной форели.',
    place: 'озеро Сон-Куль',
  },
  {
    image: work2,
    text: 'ИНКУБАЦИОННО-МАЛЬКОВЫЙ ЦЕХ ДЛЯ РЫБДЕПАРТАМЕНТА КР',
    desc: ' Инкубационно-мальковый цех выращивания мальков форели и сига ',
    perf: ' 10 млн икоинок форели и 800 тысяч икоинок сига заолин заклад   ',
    place: 'озеро Сон-Куль',
  },
  {
    image: work3,
    text: 'КУПАЛЬНЫЙ КОММЕРЧЕСКИЙ БАССЕЙН',
    desc: 'Купальный бассейн из немецкой ПВХ плёнки ',
    perf: '10м х 5м х 1,6м ',
    place: ' г. Кара-Балта',
  },
  {
    image: work4,
    text: 'ЁМКОСТЬ ДЛЯ ПИТЬЕВОЙ ВОДЫ ДЛЯ ГИПЕРМАРКЕТА МЕГАПОЛИС',
    desc: ' Накопительная емкость для питьевой воды из пищевого полипропилена',
    perf: '50м°    50м° ',
    place: ' г. Ош',
  },
  {
    image: work5,
    text: 'ОЧИСТНОЕ СООРУЖЕНИЕ ДЛЯ КОТТЕДЖНОГО ПОСЁЛКА',
    desc: ' Биологическое очистное сооружение для сточных вод рассчитанный на 400 человек',
    perf: ' 80м° в сутки ',
    place: ' с. Корумду',
  },
  {
    image: work6,
    text: 'ИНКУБАЦИОННО-МАЛЬКОВЫЙ ЦЕХ ДЛЯ ЧАСТНОГО ДОМОХОЗЯЙСТВА',
    desc: 'Инкубационно-мальковый цех для выращивания мальков радужной форели  ',
    perf: '  200 тысяч икринок радужной форели за один заклад  ',
    place: ' г. Бишкек',
  },
];
export default worksData;
