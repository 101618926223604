import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import Icon from '../../../assets/svg/icon';
import polypropyleneImg from '../../../assets/images/Полипропилен.webp';
import polypropyleneData from './PolypropyleneData';

const Polypropylene = () => {
  return (
    <Box>
      <Box id={'polypropylene'} p={{md:"100px 0", base:"50px 0 "}} bg={'#BADED4'}>
        <Container maxW={{ md: '90%', base: '100%' }} m={'auto'}>
          <Flex
            alignItems={'center'}
            ml={{ md: '140px', base: '0' }}
            justifyContent="start"
            pb={'50px'}
          >
            <Icon />
            <Heading
              ml={'20px'}
              fontSize={{ md: '46px', base: '25px' }}
              textAlign="center"
              fontWeight={'700'}
            >
              Полипропилен
            </Heading>
          </Flex>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            gap='21px'
            flexDirection={{ lg: 'row', base: 'column' }}
          >
            <Flex justifyContent={'center'} alignItems={'center'} >
              <Box
                w={'2px'}
                h={{ lg: '175px', md: '320px', sm: '230px', base: '230px' }}
                bg={'#0E6B56'}
              />
              <Text
              fontFamily={'Open Sans, sans-serif'}
                w={{ md: '589px', base: '100%' }}
                fontSize={{ md: '18px', base: '15px' }}
                fontWeight={'400'}
                ml={'20px'}
              >
                ПОЛИПРОПИЛЕН - пластический материал, отличающийся высокой
                прочностью при ударе и многократном изгибе, износостойкостью ,
                хорошими теплоизоляционными свойствами в широком диапазоне
                температур, высокой химической стойкостью, низкой паро - и
                газопроницаемостью. Стоек к кислотам, щелочам, растворам солей,
                минеральным и растительным маслам при высоких температурах.
              </Text>
            </Flex>
            <Image
              src={polypropyleneImg}
              alt={'about'}
              // w={{ xl: '578px', lg: '410px' }}
              // m={{ lg: '0', base: '25px 0 ' }}
              // h={{ xl: '410px', lg: '300px' }}
            />
          </Flex>
        </Container>
      </Box>
      <Box
        id={'polypropylene'}
        p={'50px 0'}
        bg={{ md: '#BADED4', base: '#FFFFFF' }}
      >
        <Container maxW='90%' m={'auto'}>
          <Heading
            fontSize={'18px'}
            fontWeight={'300'}
            ml={{ md: '120px', base: '0' }}
            mb={'30px'}
          >
            ПРЕИМУЩЕСТВА ИЗДЕЛИЙ ИЗ ПОЛИПРОПИЛЕНА:
          </Heading>
          <Flex
            ml={{ lg: '60px', base: '0' }}
            flexWrap="wrap"
            gap={{ xl: '0', base: '44px' }}
            alignItems={{md:"start", base:"center"}}
            justifyContent={{md:"center", base:"space-between"}}
          >
            {polypropyleneData.map((el, index) => (
              <Box
                key={index}
                display={'flex'}
                justifyContent={{md:"center", base:"space-between"}}
                alignItems={'center'}
                flexDir={{md:"column", base:"row"}}
                gap={{md:"0", base:"10px" }}
              >
                {el.icon}
                <Text
                  fontSize={{md:"10px", base:"15px"}}
                  w={{md:"167px", base:"188px"}}
                  textAlign={{md:"center", base:"start"}}
                  mt={{md:"10px", base:"0"}}
                >
                  {el.desc}
                </Text>
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Polypropylene;
