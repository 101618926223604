import React from 'react';
import { extendTheme, ChakraBaseProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/pages/Home/Home';
import './styles/global.scss';
import Los from './components/detail/Los';
import Fisheries from './components/detail/Fisheries';
import Pool from './components/detail/Pool';
import Containers from './components/detail/Containers';
import Layout from './components/Layout/Layout';
import GreaseTreaps from './components/detail/GreaseTreaps';

function App() {
  const breakpoints = {
    base: '320px',
    sm: '390px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  };

  const theme = extendTheme({ breakpoints });

  return (
    <ChakraBaseProvider theme={theme}>
        <Router>
          <Layout>
               <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/los" element={<Los />} />
            <Route path="/fisheries" element={<Fisheries />} />
            <Route path="/pool" element={<Pool />} />
            <Route path="/containers" element={<Containers />} />
            <Route path="/grease-treaps" element={<GreaseTreaps />} />
          </Routes>
          </Layout>
       
        </Router>
    </ChakraBaseProvider>
  );
}

export default App;
