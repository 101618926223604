import {
  Container,
  Box,
  Heading,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import FisheriesZero from '../../assets/images/рыбные хозяйства.jpg';
import Fisheries1 from '../../assets/images/01рыбныехозяйства.webp';
import Fisheries2 from '../../assets/images/02рыбныехозяйства.webp';
import Fisheries3 from '../../assets/images/03рыбныехозяйства.webp';
import Fisheries4 from '../../assets/images/04рыбныехозяйства.webp';
import Fisheries5 from '../../assets/images/узв 01.webp';
import Fisheries6 from '../../assets/images/узв 02.webp';
import Fisheries7 from '../../assets/images/узв 03.webp';

import { useState } from 'react';

const Fisheries = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,

    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [view, setView] = useState(false);

  const images = [
    {
      src: Fisheries1,
      type: 'image',
    },
    {
      src: Fisheries2,
      type: 'image',
    },
    {
      src: Fisheries3,
      type: 'image',
    },
    {
      src: Fisheries4,
      type: 'image',
    },
    {
      src: Fisheries5,
      type: 'image',
    },
    {
      src: Fisheries6,
      type: 'image',
    },
    {
      src: Fisheries7,
      type: 'image',
    },
  ];

  const list = [
    'Контролируемое успешное выращивание рыбы ',
    'Долговечная, бесперебойная работа Вашего бизнеса',
    'Мобильность и компактность',
    'Сухой пол в рыбном хозяйстве',
    'Удобство и практичность в работе',
    'Экономия воды',
    'Удобное управление уровнем воды в емкостях с рыбой',
    'Легкость в обслуживании',
  ];

  const handleClick = index => {
    new Fancybox(images, { startIndex: index });
  };

  return (
    <Box bg={'#fff'}>
      <Heading
        p={{ md: '100px 0 50px 100px ', base: '120px 0 50px 20px' }}
        ml={'10px'}
        fontSize="18px"
        fontWeight={'700'}
        lineHeight="normal"
      >
        РЫБНЫЕ ХОЗЯЙСТВА
      </Heading>
      <Image
        src={FisheriesZero}
        alt="los"
        objectFit={'contain'}
        bg={'#BADED4'}
      />
      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Heading
          fontSize={{ md: '20px', base: '18px' }}
          color="#000000"
          p={{ md: '100px 0 50px 0 ', base: '70px 0 25px 0 ' }}
        >
          Рыбное хозяйство в КР, УЗВ
        </Heading>
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
          Благодаря природному богатству нашей страны и трудолюбию нашего народа
          бурно развивается рыбное хозяйство в последние годы. Все больше
          строятся инкубационно-мальковые цеха и рыбные фермы по всем регионам
          Кыргызстана. Ежегодно увеличивается производство рыбы, особенно
          форели, карп и сомов. Рыбная продукция идет не только на внутренний
          рынок, но и на экспорт в страны ЕАЭС. <br />
          <br />
          Компания Zero Waste тоже внесла свой вклад в развитие этой отрасли. А
          именно, сдали в эксплуатацию более 30-ти современные
          инкубационно-мальковые цеха производительностью от 100 тысяч до 10
          миллиона мальков в год во всех регионах Кыргызстана. Установили более
          10-ти промышленные Установки замкнутого водоснабжения (УЗВ)
          производительностью от 50 до 250 тонн рыбы в год для рыбных хозяйств в
          странах СНГ. <br />
          <br />
          <br />
          УЗВ мировой стандарт для успешного, интенсивного и гарантируемо
          управляемого выращивания рыбы. УЗВ представляет собой емкости для
          выращивания рыбы и система водоподготовки включающая в себя –
          механическую и биологическую очистку, стерилизацию, температурную
          стабилизацию, насыщение кислородом, закольцованную в единую систему.
          Обычно долив (и соответственно сброс воды) в сутки составляет от 5 до
          30% от общего объёма воды в системе. Все это благотворно влияет на
          выживаемость и быстрый рост икринок и мальков, а также увеличивает
          скорость набора веса рыбы.
        </Text>

   
        <Box display={{ md: 'none', base: 'block' }}>
          <motion.button
            onClick={() => setView(!view)}
            style={{
              background: 'transparent',
              color: '#428875',
              borderRadius: '20px',
            }}
          >
            {view ? 'Скрыть' : 'Далее...'}
          </motion.button>
        </Box>
      </Container>

      <Box bg="#BADED4" p={{ md: '60px', base: '20px' }}>
        <Box display={{ md: 'block', base: 'none' }}>
          <Slider {...settings}>
            {images.map((el, index) => (
              <Image
                onClick={() => {
                  handleClick(index);
                }}
                cursor="pointer"
                src={el.src}
                alt="los"
                padding={'0 10px'}
                maxW={'1000px'}
                maxH={'300px'}
              />
            ))}
          </Slider>
        </Box>

        <Box
          display={{ md: 'none', base: 'flex' }}
          justifyContent={'center'}
          flexWrap={'wrap'}
          p="40px 0"
          alignItems={'center'}
          flexDirection="column"
          gap="35px 0 "
        >
          {images.map((el, index) => (
            <Box
              key={index}
              bg={'#BADED4'}
              borderRadius={'10px'}
              maxW={{ md: '476px' }}
              maxH={{ md: '403px' }}
              m={{ md: '0 50px', base: '0' }}
            >
              <Image src={el.src} w={'100%'} h={'200px'} rounded="10px" />
            </Box>
          ))}
        </Box>
      </Box>

      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Heading
          fontSize={{ md: '20px', base: '18px' }}
          color="#000000"
          p={{ md: '100px 0 50px 0 ', base: '70px 0 25px 0 ' }}
        >
          Конкурентные преимущества УЗВ Zero Waste
        </Heading>
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
          УЗВ Zero Waste изготавливается по современной европейской технологии
          из высококачественного российского пищевого полипропилена, который
          экологически безопасен для рыб. Сварка швов производится на
          швейцарском оборудовании. Это обеспечивает 100% герметичность и срок
          службы до 30 лет при должной эксплуатации.
          <br /> <br />
          УЗВ Zero Waste это:
          <br />
        </Text>
        <UnorderedList>
          {list.map((el, index) => (
            <ListItem fontSize={'16px'} color="#000000" key={index}>
              {el}
            </ListItem>
          ))}
        </UnorderedList>
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
          <br />
          Наше УЗВ превосходит по многим параметрам не только местные
          альтернативы, но и зарубежные. Например, турецкие и российские.
          <br />
          <br />
          Наши специалисты с зарубежным опытом индивидуально изготовят для Вас
          УЗВ с учетом местности, температуры воды и выращиваемых видов рыбы.
          Накопившийся опыт работы в этой отрасли позволяет нам также
          предоставлять нашим клиентам помощь от приобретения икринок и кормов
          до сбыта рыбной продукции.
          <br />
          <br />
          Обращайтесь, всегда рады помочь в становлении и развитии Вашего
          рыбного хозяйства
          <br />
          <br />
        </Text>
      </Container>
    </Box>
  );
};

export default Fisheries;
