import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import Icon from '../../../assets/svg/icon';
import advantagesData from './AdvantagesData';

const Advantages = () => {
  return (
    <Box
      id={'advantages'}
      p={{ md: '100px 0', base: '50px 0' }}
      bg={'rgba(255, 255, 255, 1)'}
    >
      <Container maxW={{ md: '90%', base: '100%' }} m={'auto'}>
        <Flex
          alignItems={'center'}
          ml={{ md: '140px', base: '0' }}
          justifyContent={{ md: 'start', base: 'center' }}
          pb={'50px'}
        >
          <Icon />
          <Heading
            ml={'10px'}
            fontSize={{ md: '46px', base: '25px' }}
            fontWeight={'700'}
          >
            Наши преимущества
          </Heading>
        </Flex>
        <Box
          display={'flex'}
          justifyContent={'center'}
          m={'auto'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap={{ md: '0', sm: '40px', base: '10px' }}
        >
          {advantagesData.map((el, index) => (
            <Box
              key={index}
              display={'flex'}
              flexDir={'column'}
              alignItems={'center'}
              w={{ md: '240px', base: '270px' }}
              h={{ md: '270px', base: '285px' }}
              m={{ md: '10px 30px', base: '0' }}
              bg={'#fff'}
              border={'0.50px rgba(0, 0, 0, 0.10) solid'}
            >
              <Image
                src={el.icon}
                w={{ md: '115px', base: '76px' }}
                mb={{ md: '14px', base: '9px' }}
                mt={'10px'}
              />
              <Heading
                textAlign={'center'}
                fontWeight={'800'}
                fontSize={{ md: '14px', base: '16px' }}
                color="#000000"
                pb={{ md: '14px', base: '9px' }}
              >
                {el.title}
              </Heading>
              <Text
                textAlign={'center'}
                color={'#525151'}
                fontSize={{ md: '12px', base: '15px' }}
                fontWeight={'400'}
                w={{ md: '225px', base: '252px' }}
              >
                {el.desc}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Advantages;
