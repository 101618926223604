import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Flex,
  Center,
  Button,
  ButtonGroup,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import socialData from './socialData';
// import { ChevronLeftIcon, ChevronRightIcon } from 'react-icons/all';

const Hero = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderContent = [
    {
      id: 1,

      content: (
        <Box id={'hero'}>
          <Flex
            pt={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '550px',
            }}
            ml={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              lineHeight="normal"
            >
              Многопрофильная компания по изготовлению комплексных инженерных
              сооружений по современной зарубежной технологии
            </Text>
          </Flex>
        </Box>
      ),
    },
    {
      id: 2,
      content: (
        <Box id={'hero2'}>
          <Flex
            pos={'absolute'}
            top={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '590px',
            }}
            left={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
            bg={'rgba(0, 0, 0, 0.5)'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              color={'#fff'}
              lineHeight="normal"
            >
              Установка мобильного локального очистного сооружения на 100
              человек для дома культуры ROYAL GATE в селе Жети-Огуз
            </Text>
          </Flex>
        </Box>
      ),
    },
        {
      id: 3,
      content: (
        <Box id={'hero3'}>
          <Flex
            pos={'absolute'}
            top={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '590px',
            }}
            left={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
            bg={'rgba(0, 0, 0, 0.5)'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              color={'#fff'}
              lineHeight="normal"
            >
              Установка мобильного локального очистного сооружения на 100
              человек для дома культуры ROYAL GATE в селе Жети-Огуз
            </Text>
          </Flex>
        </Box>
      ),
    },
    {
      id: 4,
      content: (
        <Box id={'hero4'}>
          <Flex
            pos={'absolute'}
            top={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '590px',
            }}
            left={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
            bg={'rgba(0, 0, 0, 0.5)'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              color={'#fff'}
              lineHeight="normal"
            >
              Установка мобильного локального очистного сооружения на 100
              человек для дома культуры ROYAL GATE в селе Жети-Огуз
            </Text>
          </Flex>
        </Box>
      ),
    },
    {
      id: 5,
      content: (
        <Box id={'hero5'}>
          <Flex
            pos={'absolute'}
            top={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '590px',
            }}
            left={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
            bg={'rgba(0, 0, 0, 0.5)'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              color={'#fff'}
              lineHeight="normal"
            >
              Установка мобильного локального очистного сооружения на 100
              человек для дома культуры ROYAL GATE в селе Жети-Огуз
            </Text>
          </Flex>
        </Box>
      ),
    },
        {
      id: 6,
      content: (
        <Box id={'hero6'}>
          <Flex
            pos={'absolute'}
            top={{
              md: '400px',
              sm: '500px',
              base: '500px',
              lg: '354px',
              xl: '590px',
            }}
            left={{ base: '20px', md: '10px', lg: '156px' }}
            alignItems={'center'}
            bg={'rgba(0, 0, 0, 0.5)'}
          >
            <Box w={'1px'} h={{ md: '90px', base: '72px' }} bg={'#007D5F'} />
            <Text
              w={{ lg: '781px', md: '60%', sm: '90%' }}
              ml={{ lg: '18px', md: '16px', sm: '5px', base: '10px' }}
              fontSize={{ md: '22px', base: '15px' }}
              fontWeight={'400'}
              color={'#fff'}
              lineHeight="normal"
            >
              Установка мобильного локального очистного сооружения на 100
              человек для дома культуры ROYAL GATE в селе Жети-Огуз
            </Text>
          </Flex>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(prev => (prev + 1) % sliderContent.length);
    }, 9000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleDotClick = index => {
    setActiveSlide(index);
  };
  return (
    <Box className="zerowaste">
      <Box pos={'relative'}>
        <motion.div
          key={activeSlide}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {sliderContent[activeSlide].content}
        </motion.div>

        <Center
          my="4"
          position={'absolute'}
          bottom={'27px'}
          left={'25%'}
          right={'25%'}
          transition={'2s'}
        >
          <ButtonGroup spacing="1">
            {sliderContent.map((slide, index) => (
              <Button
                key={slide.id}
                size="xs"
                variant="solid"
                bg={'transparent'}
                _hover={{ bg: 'transparent' }}
                colorScheme={'gray'}
                onClick={() => handleDotClick(index)}
              >
                <Box
                  w={{ md: '15px', base: '10px' }}
                  h={{ md: '15px', base: '10px' }}
                  rounded={'50%'}
                  border={' 1px solid #fff'}
                  bg={activeSlide === index ? '#fff' : ''}
                />
              </Button>
            ))}
          </ButtonGroup>
        </Center>
      </Box>

      <Container maxW={'90%'} m={'auto'}>
        <Box
          position={'fixed'}
          zIndex={'80'}
          left={'30px'}
          bottom={'100px'}
          display={{ lg: 'block', base: 'none' }}
        >
          <Flex flexDir={'column'}>
            {socialData.map((el, index) => (
              <a
                href={el.link}
                key={index}
                cursor={'pointer'}
                target="_blank"
              >
                <Box m={'4px 0'}>{el.icon}</Box>
              </a>
            ))}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
