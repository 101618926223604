import React from 'react';

const Icon = () => {
  return (
    <svg width='34' height='44' viewBox='0 0 34 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.5016 42.6152C30.5086 42.4577 30.5352 41.1153 30.6139 40.4415L29.4257 42.228C29.0144 42.9714 27.0151 42.8845 26.956 42.3744L27.63 37.4555L25.3794 42.2455C25.218 42.9466 22.8298 42.356 22.9383 42.1147L24.4995 33.7266L21.8263 41.808C21.479 42.5801 19.2196 42.473 19.1605 41.9629L21.1405 32.2159L17.7077 41.9864C17.4762 42.5012 15.2381 42.1328 15.3028 41.7751L18.0767 29.1939L13.9299 41.8346C9.14519 40.843 6.13328 38.2187 5.14603 37.207C-2.12035 29.7613 0.350929 20.9315 2.49487 17.4473C4.28484 20.6507 6.08816 23.9954 12.4061 26.8369C16.251 28.7209 17.403 27.549 17.593 26.8489C18.1775 24.6941 15.8678 24.9125 13.6647 23.8249C1.82846 18.5015 3.98544 6.22308 5.48958 2.45117C7.28391 -1.53837 10.3984 1.37099 9.49399 3.38184C7.85812 8.21628 8.29842 10.3871 8.72305 10.8682C23.048 4.15908 33.692 15.4255 33.34 25.6146L32.1792 42.3059C32.0001 43.7727 30.4805 43.0893 30.5016 42.6152Z'
        fill='#0E6B56' />
    </svg>

  );
};

export default Icon;
