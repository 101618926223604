import React from 'react';

const Youtube = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' width='30' height='30' rx='15' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M12.4469 20.2926C12.1527 20.2056 11.8842 20.0507 11.6642 19.8411C11.4442 19.6316 11.2791 19.3734 11.1827 19.0884C10.6322 17.6155 10.4691 11.4652 11.5293 10.2511C11.8822 9.85609 12.3804 9.61291 12.9158 9.57432C15.7602 9.27576 24.5481 9.31557 25.5574 9.67384C25.8413 9.76374 26.1006 9.91519 26.316 10.1168C26.5314 10.3185 26.6973 10.5651 26.8012 10.8382C27.4027 12.3609 27.423 17.8942 26.7196 19.3571C26.533 19.7379 26.2206 20.0464 25.8327 20.2329C24.7724 20.7504 13.8538 20.7405 12.4469 20.2926ZM16.9632 17.4065L22.0606 14.819L16.9632 12.2116V17.4065Z'
            fill='#0E6B56' />
      <defs>
        <filter id='filter0_d_2837_3400' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2837_3400' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2837_3400' result='shape' />
        </filter>
      </defs>
    </svg>

  );
};

export default Youtube;
