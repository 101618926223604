import Advantage1 from '../../../assets/images/advantage1.webp';
import Advantage2 from '../../../assets/images/advantage2.webp';
import Advantage3 from '../../../assets/images/advantage3.webp';
import Advantage4 from '../../../assets/images/advantage4.webp';
import Advantage5 from '../../../assets/images/advantage5.webp';
import Advantage6 from '../../../assets/images/advantage6.webp';
import Advantage7 from '../../../assets/images/advantage7.webp';
import Advantage8 from '../../../assets/images/advantage8.webp';

const advantagesData = [
  {
    icon: Advantage2,
    title: 'СПЕЦИАЛИСТЫ',
    desc: 'Прежде всего это наши специалисты, имеющие высокую квалификацию в своей отрасли и получающие удовольствие от своей работы.',
  },
  {
    icon: Advantage1,
    title: 'ПРОИЗВОДСТВЕННЫЙ ЦЕХ',
    desc: 'Наличие собственного производственного цеха в Бишкеке, позволяет нам предлагать нашим клиентам самые низкие цены и высокую скорость исполнения.',
  },
  {
    icon: Advantage3,
    title: 'ИНСТРУМЕНТЫ',
    desc: 'Использование в производстве лучших в своей отрасли швейцарских инструментов и оборудований Leister позволяет нам добиться высочайшего качества на рынке.',
  },
  {
    icon: Advantage4,
    title: 'ЛУЧШИЕ КОМПЛЕКТУЮЩИЕ',
    desc: 'Мы не экономим на качестве комплектующих в наших сооружениях. Используем только немецкие и японские. Благодаря этому, значительно увеличивается их срок службы.',
  },
  {
    icon: Advantage5,
    title: 'ОПЫТ',
    desc: 'Огромный опыт работы наших специалистов позволяет нам изготавливать сложные сооружения любой формы и размеров.',
  },
  {
    icon: Advantage6,
    title: 'СОВРЕМЕННЫЕ ТЕХНОЛОГИИ',
    desc: 'Использование самых современных технологий обеспечивает бесперебойную работу и лучшие показатели энергопотребление наших сооружений.',
  },

  {
    icon: Advantage7,
    title: 'МАТЕРИАЛЫ',
    desc: 'Работаем только с высококачественными чешскими и российскими пищевым полипропиленом, ПНД и ПВХ материалами. Поэтому мы предоставляем гарантию до 15 лет и спокойны за здоровье наших клиентов',
  },
  {
    icon: Advantage8,
    title: 'МЕСТОПОЛОЖЕНИЕ',
    desc: 'Расположение нашей компании в Бишкеке дает нашим клиентам в Кыргызстане уверенность в завтрашнем дне и своевременное обслуживание и постоянную поддержку.',
  },
];
export default advantagesData;
