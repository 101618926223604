import {
  Container,
  Box,
  Heading,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import PoolZero from '../../assets/images/плавательный бассейн.jpg';
import Pool1 from '../../assets/images/01плавательныйбассейн.webp';
import Pool2 from '../../assets/images/02плавательныйбассейн.webp';
import Pool3 from '../../assets/images/03плавательныйбассейн.webp';
import Pool4 from '../../assets/images/04плавательныйбассейн.webp';
import Pool5 from '../../assets/images/05плавательныйбассейн.webp';
import { useState } from 'react';

const Pool = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,

    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [view2, setView2] = useState(false);

  const images = [
    {
      src: Pool1,
      type: 'image',
    },
    {
      src: Pool2,
      type: 'image',
    },
    {
      src: Pool3,
      type: 'image',
    },
    {
      src: Pool4,
      type: 'image',
    },
    {
      src: Pool5,
      type: 'image',
    },
  ];

  const list = [
    'Долговечность материала – при должной эксплуатации срок службы до 30 лет',
    'Невысокая цена – изготовление и эксплуатация обходится намного дешевле',
    'Любые формы – возможность изготовления любой формы, размеров и глубины',
    'Ударопрочность и выносливость – устойчив к механическим и химическим воздействиям',
    'Устойчив к температурным колебаниям – вынослив к высоким и низким температурам',
    'Экологичность – безопасно для здоровья людей и окружающей среды',
    'ЛГладкая поверхность – не задерживает грязь, легко обслуживается',
    'Устойчив к ультрафиолету – надолго сохраняет цвет и внешний вид',
  ];

  const handleClick = index => {
    new Fancybox(images, { startIndex: index });
  };

  return (
    <Box bg={'#fff'}>
      <Heading
        p={{ md: '100px 0 50px 100px ', base: '120px 0 50px 20px' }}
        ml={'10px'}
        fontSize="18px"
        fontWeight={'700'}
        lineHeight="normal"
      >
        ПЛАВАТЕЛЬНЫЕ БАССЕЙНЫ
      </Heading>
      <Image src={PoolZero} alt="los" objectFit={'contain'} bg={'#BADED4'} />
      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '20px', base: '50px' }}
      >
        <Text
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
          p={'40px 0 10px 0'}
        >
         Бассейн из полипропилена – отличный современный плавательный бассейн для частных домов, коттеджей, пансионатов и спортивных залов. Выгодно выделяется среди других видов бассейнов, в том числе надувных, каркасных бассейнов и бетонных с отделкой плиткой. Имеет лучшее решение по соотношению цены и качества.  <br />
          <br />
          Преимущества бассейна из полипропилена и ПВХ плёнки:  <br />
        </Text>
        <UnorderedList>
          {list.map((el, index) => (
            <ListItem fontSize={'16px'} color="#000000" key={index}>
              {el}
            </ListItem>
          ))}
        </UnorderedList>
      </Container>
      <Container maxW={{ md: '67%', base: '95%' }} m={'auto'} pb="10px">
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
        Плавательные бассейны Zero Waste изготавливаются из высококачественного российского пищевого полипропилена и плёнки ПВХ производства Германии, России, Ирана и Китая, с применением современных технологий, испанских и российских комплектующих. В производстве используется самое современное и лучшее Швейцарское оборудование, что в разы увеличивает степень герметичности по сравнению изготовления на других оборудованиях. Благодаря этому значительно увеличивается срок службы. Бассейны оснащаются фильтрационными и нагревательными системами. Наши опытные специалисты индивидуально изготовят для Вас прекрасный крытый или открытый бассейн по Вашему заказу и дизайну, любой формы и размеров.
          <br /> <br />
        </Text>

        <Text
          display={{ md: 'block', base: view2 ? 'block' : 'none' }}
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
        >
          <br />
          Плавательные бассейны Zero Waste отлично подходят как для активного отдыха в Вашем доме, так и для коммерческих целей.
          <br />
          <br />
          Всегда готовы ответить на все Ваши вопросы. Звоните и пишите нам.
          <br />
          <br />
        </Text>
      </Container>

      <Box bg="#BADED4" p={{ md: '60px', base: '20px' }}>
        <Box display={{ md: 'block', base: 'none' }}>
          <Slider {...settings}>
            {images.map((el, index) => (
              <Image
                onClick={() => {
                  handleClick(index);
                }}
                cursor="pointer"
                src={el.src}
                alt="los"
                padding={'0 10px'}
                maxW={'1000px'}
                maxH={'300px'}
              />
            ))}
          </Slider>
        </Box>

        <Box
          display={{ md: 'none', base: 'flex' }}
          justifyContent={'center'}
          flexWrap={'wrap'}
          p="40px 0"
          alignItems={'center'}
          flexDirection="column"
          gap="35px 0 "
        >
          {images.map((el, index) => (
            <Box
              key={index}
              bg={'#BADED4'}
              borderRadius={'10px'}
              maxW={{ md: '476px' }}
              maxH={{ md: '403px' }}
              m={{ md: '0 50px', base: '0' }}
            >
              <Image src={el.src} w={'100%'} h={'200px'} rounded="10px" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Pool;
