import Service1 from '../../../assets/images/service1.webp';
import Service2 from '../../../assets/images/баннер6.webp';
import Service3 from '../../../assets/images/service4.png';
import Service4 from '../../../assets/images/service5.png';
import Service5 from '../../../assets/images/service6.png';

const servicesData = [
  {
    image: Service2,
    title: 'ЛОКАЛЬНЫЕ ОЧИСТНЫЕ СООРУЖЕНИЯ',
    desc: 'Стационарные и мобильные ЛОС для предприятий и населенных мест любой формы и производительности',
    link : "/los"
  },
  {
    image:  Service3,
    title: 'РЫБНЫЕ ХОЗЯЙСТВА',
    desc: '    Инкубационные цеха для рыбы, Установка замкнутого водоснабжения (УЗВ)',
    link : "/fisheries",
  },
  {
    image: Service1,
    title: 'ПЛАВАТЕЛЬНЫЕ БАССЕЙНЫ',
    desc: 'Крытые и открытые плавательные бассейны любой формы и размеров из ПВХ и Полипропилена',
    link : "/pool"
  },
  {
    image: Service4,
    title: 'ЕМКОСТИ ДЛЯ ЖИДКОСТЕЙ',
    desc: 'Нестандартные емкости для питьевой воды, молока и других пищевых и непищевых жидкостей',
    link : "/containers"
  },
  {
    image: Service5,
    title: 'ЖИРОУЛОВИТЕЛИ ',
    desc: 'Жироуловители любой производительности для домашних хозяйств и пищевых предприятий ',
    link : "/grease-treaps"
  },

];
export default servicesData;
