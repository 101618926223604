import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';

import About from '../../components/Main/About/About';
import Services from '../../components/Main/Services/Services';
import Works from '../../components/Main/Works/Works';
import Advantages from '../../components/Main/Advantages/Advantages';
import Polypropylene from '../../components/Main/Polypropylene/Polypropylene';
import Contacts from '../../components/Main/Contacts/Contacts';
import Hero from '../../components/Main/Hero/Hero';

const Home = () => {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoad(false), 600);
  }, []);
  return (
    <>
      {load ? (
        <Loading />
      ) : (
          <>
             <Hero />
          <About />
          <Services />
          <Works />
          <Advantages />
          <Polypropylene />
          <Contacts />
          </>
      )}
    </>
  );
};

export default Home;
