import {
  Container,
  Box,
  Heading,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { motion } from 'framer-motion';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import LosZero from '../../assets/images/очистное сооружение схема.png';
import Los1 from '../../assets/images/01очистноесооружение.webp';
import Los2 from '../../assets/images/лос 01.webp';
import Los7 from '../../assets/images/лос 02.webp';
import Los8 from '../../assets/images/лос 03.webp';
import Los3 from '../../assets/images/03очистноесооружение.webp';
import Los4 from '../../assets/images/04очистноесооружение.webp';
import Los5 from '../../assets/images/05очистноесооружение.webp';
import Los6 from '../../assets/images/06очистноесооружение.webp';
import { useState } from 'react';

const Los = () => {
  
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    
    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        }
    ]
};
  const [view, setView] = useState(false);
  const [view2, setView2] = useState(false);

  const images = [
    {
      src: Los1,
      type: 'image',
    },
    {
      src: Los2,
      type: 'image',
    },
    {
      src: Los7,
      type: 'image',
    },  {
      src: Los8,
      type: 'image',
    }, {
      src: Los3,
      type: 'image',
    },
    {
      src: Los4,
      type: 'image',
    },
    {
      src: Los5,
      type: 'image',
    },
    {
      src: Los6,
      type: 'image',
    },
  ]

  const list = [
    'Эффективность очистки сточных вод до 98%',
    'Высокая производительность',
    'Высокое качество',
    'Долговечность и надежность',
    'Низкое энергопотребление',
    'Компактный размер и мобильность',
    'Любой формы и производительности',
    'Безопасно для окружающей среды',
    'Лучшие цены на рынке',
    'Постоянное сервисное обслуживание и техподдержка от нас',
  ];

  const handleClick = (index) => {
    new Fancybox(images, { startIndex: index });
  };

  return (
    <Box bg={'#fff'}>
      <Heading
        p={{ md: '100px 0 50px 100px ', base: '120px 0 50px 20px' }}
        ml={'10px'}
        fontSize='18px'
        fontWeight={'700'}
        lineHeight="normal"
      >
        ЛОКАЛЬНЫЕ ОЧИСТНЫЕ СООРУЖЕНИЯ
      </Heading>
      <Image src={LosZero} alt="los" objectFit={'contain'} bg={'#BADED4'} />
      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Heading
          fontSize={{ md: '20px', base: '18px' }}
          color="#000000"
          p={{ md: '100px 0 50px 0 ', base: '70px 0 25px 0 ' }}
        >
          Краткое описание и принципы работы
        </Heading>
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
          Локальное очистное сооружение (ЛОС) — это сложная инженерная
          конструкция, предназначенная для очистки бытовых и промышленных стоков
          в местах, не имеющих подключения к центральной канализации. Часто ЛОС
          называют мини городской канализацией, т.к. сточные воды проходят
          схожие этапы очистки до норм, безопасных для окружающей среды. <br />
          <br />
          Система биологической очистки сточных вод ЛОС Zero Waste поэтапно
          очищает отходы и нейтрализует запахи. Очищенная до 98% вода подается в
          грунт или на полив газонов и деревьев. <br />
        </Text>

        <Text
          display={{ md: 'block', base: view ? 'block' : 'none' }}
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
        >
          <br />
          Локальное очистное сооружение состоит из 4-х технологических отсеков.
          Загрязненные сточные воды поступают самотеком в 1-ый отсек
          «Накопитель». Где происходит усреднение концентрации, постоянная
          циркуляция и измельчение поступающих стоков. Затем сточные воды плавно
          самотеком поступают во 2-ой отсек «Аэротенк». Искусственно насыщается
          кислородом с помощью аэраторов. Работа аэраторов контролируется
          таймером. Часть сточных вод возвращается аэрлифтом в 1-ый отсек. А,
          достаточно насыщенный кислородом равномерно поступает самотеком в 3-ий
          отсек «Бактериальный».
          <br />
          <br />
          Здесь в активном иле происходит бактериальное очищение автотрофными
          облигатными аэробами. Отходы расщепляются на простейшие элементы,
          безопасные для окружающей среды. Осветленная, избавленная от запаха
          вода поступает в 4-ый отсек «Осветленная вода». Где вода накапливается
          и по достижению достаточного уровня направляется насосом наружу.
          Уровень воды контролируется с поплавковым датчиком.
          <br /> <br />
        </Text>
        <Box display={{ md: 'none', base: 'block' }}>
          <motion.button
            onClick={() => setView(!view)}
            style={{
              background: 'transparent',
              color: '#428875',
              borderRadius: '20px',
            }}
          >
            {view ? 'Скрыть' : 'Далее...'}
          </motion.button>
        </Box>
      </Container>

      <Box bg="#BADED4" p={{ md: '60px', base: '20px' }}>
        <Box display={{ md: 'block', base: 'none' }}>
        <Slider {...settings}
        
        >
          {images.map((el, index) => (
              <Image
                onClick={() => {
                  handleClick(index);
                }}
                cursor="pointer"
                src={el.src}
                alt="los"
                padding={'0 10px'}
                maxW={'1000px'}
                maxH={'300px'}
              />
          ))}
        </Slider>
        </Box>

        <Box
          display={{ md: 'none', base: 'flex' }}
          justifyContent={'center'}
          flexWrap={'wrap'}
          p="40px 0"
          alignItems={'center'}
          flexDirection="column"
          gap="35px 0 "
        >
          {images.map((el, index) => (
            <Box
              key={index}
              bg={'#BADED4'}
              borderRadius={'10px'}
              maxW={{ md: '476px' }}
              maxH={{ md: '403px' }}
              m={{ md: '0 50px', base: '0' }}
            >
              <Image src={el.src} w={'100%'} h={'200px'} rounded="10px" />
            </Box>
          ))}
        </Box>
      </Box>

      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Heading
          fontSize={{ md: '20px', base: '18px' }}
          color="#000000"
          p={{ md: '100px 0 50px 0 ', base: '70px 0 25px 0 ' }}
        >
          Конкурентные преимущества ЛОС Zero Waste
        </Heading>
        <Text fontSize={'16px'} color="#000000" textAlign="justify">
          Локальное очистное сооружение (ЛОС) Zero Waste производится по
          современной европейской технологии из высококачественного российского
          пищевого полипропилена, немецких и японских комплектующих. Продукция
          изготавливается высоко квалицированными специалистами с зарубежным
          опытом на собственном производственном цехе в Бишкеке. Благодаря этому
          наше ЛОС имеет следующие достоинства:
          <br /> <br />
        </Text>
        <UnorderedList>
          {list.map((el, index) => (
            <ListItem fontSize={'16px'} color="#000000" key={index}>
              {el}
            </ListItem>
          ))}
        </UnorderedList>
        <Text
          display={{ md: 'block', base: view2 ? 'block' : 'none' }}
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
        >
          <br />
          Наше ЛОС работает круглый год, полностью автоматизирована,
          безотходное. А также не требует расходных материалов и обслуживания со
          стороны клиента. Соответствует экологическим нормативным требованиям.
          <br />
          <br />
          ЛОС изготавливается из высококачественного полипропилена, а сварка
          швов производится на швейцарском оборудовании. Что обеспечивается 100%
          герметичность и службу конструкции до 30 лет.
          <br />
          <br />
          Больше не надо переживать за неприятные запахи и загрязнение
          окружающей среды. Не нужно будет подключаться к канализации и платить
          каждый месяц коммунальные услуги. Перестанете беспокоится о заполнении
          септика, вызывать и платить за откачку. Требуется лишь сервисное
          обслуживание 2-3 раза в год, во время которого чистятся фильтры
          аэраторов и активный ил. Наша компания предоставляет бесплатное
          сервисное обслуживание на год.
          <br />
          <br />
          Мы изготавливаем не только стационарные, но и мобильные ЛОС. Мобильные
          устанавливаются в железных контейнерах и могут легко переносится по
          мере необходимости клиента. Каждый ЛОС изготавливается индивидуально,
          чтобы формы и размеры соответствовали пожеланию клиента.
          <br />
          <br />
          Всегда готовы ответить на все Ваши вопросы. Звоните и пишите нам.
        </Text>
        <Box display={{ md: 'none', base: 'block' }}>
          <motion.button
            onClick={() => setView2(!view2)}
            style={{
              background: 'transparent',
              color: '#428875',
              borderRadius: '20px',
            }}
          >
            {view2 ? 'Скрыть' : 'Далее...'}
          </motion.button>
        </Box>
      </Container>
    </Box>
  );
};

export default Los;
