import { Box, Heading, Image, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

const Map = ({ text, image, desc, perf, place, index }) => {
  const [content, setContent] = useState(false);
  return (
    <Box
      pos={'relative'}
      key={index}
      w={'330px'}
      h={'200px'}
      m={{ md: '47px 30px', base: '47px 0' }}
      bg={'#fff'}
      borderRadius={'10px'}
    >
      <Image
        onClick={() => setContent(!content)}
        cursor={'pointer'}
        display={content ? 'none' : 'block'}
        src={image}
        w={'100%'}
        h={'100%'}
        alt={'наша работа'}
        rounded={'10px 10px 0 0 '}
        border={'1px solid rgba(0, 0, 0, 0.10)'}
      />
      <Box
        onClick={() => setContent(false)}
        display={content ? 'flex' : 'none'}
        flexDirection={'column'}
        gap={'10px'}
        cursor={'pointer'}
        bg={'#fff'}
        rounded={'10px 10px 0 0 '}
        border={'1px solid rgba(0, 0, 0, 0.10)'}
        h={'200px'}
        p={'20px 10px 0 20px'}
      >
        <Box color={'#000'} fontWeight={'400'} fontSize={'15px'}>
          <span style={{ color: '#000', fontSize: '16px', fontWeight: '600' }}>
            Описание :
          </span>{' '}
          {desc}
        </Box>
        <Heading color={'#000'} fontWeight={'400'} fontSize={'15px'}>
          <span style={{ color: '#000', fontSize: '16px', fontWeight: '600' }}>
            Производительность:
          </span>{' '}
          {perf}
        </Heading>
        <Heading color={'#000'} fontWeight={'400'} fontSize={'15px'}>
          <span style={{ color: '#000', fontSize: '16px', fontWeight: '600' }}>
            Местоположение:
          </span>{' '}
          {place}
        </Heading>
      </Box>

      <Text
        textAlign={'center'}
        fontWeight={'800'}
        fontFamily={'Piazzolla, sans-serif'}
        fontSize={'13px'}
        p={'10px 5px'}
        bg={'#fff'}
        rounded={'0 0 10px 10px'}
      >
        {text}
      </Text>
    </Box>
  );
};

export default Map;
