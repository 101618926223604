import React from 'react';

const PhoneContactsSvg = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.7422 17.3011V20.266C21.7433 20.5412 21.6869 20.8136 21.5767 21.0658C21.4664 21.318 21.3047 21.5444 21.1018 21.7305C20.899 21.9165 20.6596 22.0582 20.3988 22.1464C20.1381 22.2346 19.8618 22.2673 19.5877 22.2425C16.5466 21.9121 13.6253 20.8729 11.0587 19.2085C8.67086 17.6911 6.64635 15.6666 5.12899 13.2787C3.45876 10.7005 2.41934 7.76504 2.09494 4.71024C2.07024 4.43694 2.10272 4.1615 2.19031 3.90144C2.27789 3.64139 2.41867 3.40242 2.60367 3.19975C2.78868 2.99708 3.01385 2.83516 3.26487 2.72429C3.51588 2.61341 3.78723 2.55602 4.06164 2.55576H7.02651C7.50614 2.55104 7.97111 2.72088 8.33478 3.03363C8.69844 3.34638 8.93597 3.7807 9.0031 4.25562C9.12824 5.20445 9.36032 6.13607 9.6949 7.03272C9.82787 7.38646 9.85665 7.77089 9.77783 8.14048C9.699 8.51007 9.51589 8.84931 9.25017 9.11802L7.99504 10.3731C9.40193 12.8474 11.4506 14.896 13.9248 16.3029L15.1799 15.0478C15.4486 14.7821 15.7879 14.5989 16.1575 14.5201C16.527 14.4413 16.9115 14.4701 17.2652 14.603C18.1619 14.9376 19.0935 15.1697 20.0423 15.2948C20.5224 15.3626 20.9608 15.6044 21.2743 15.9743C21.5877 16.3442 21.7542 16.8164 21.7422 17.3011Z"
          stroke="#0E6B56"
          strokeWidth="1.35537"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default PhoneContactsSvg;
