const navData = [
  {
    name: 'Главная',
    link: '/#'
  },
  {
    name: 'О Нас',
    link: '/#about'
  },
  {
    name: 'Услуги',
    link: '/#services'
  },
  {
    name: 'Работы',
    link: '/#works'
  },
  {
    name: 'Преимущества',
    link: '/#advantages'
  },
  {
    name: 'Полипропилен',
    link: '/#polypropylene'
  },
  {
    name: 'Контакты',
    link: '/#contacts'
  },

];

export default navData;
