import React from 'react';
import { Box, Container, Flex, Heading, Text  } from '@chakra-ui/react';
import socialData from '../Main/Hero/socialData';

const Footer = () => {
  return (
    <Box id={'footer'} bg={'#0E6B56'} p={'15px 0'}>
      <Container maxW={'90%'} m={'auto'}>

        <Box display={{lg : 'none', base: "flex"}} flexDirection='column' alignItems='center' justifyContent='space-between'>
        <Heading color='#fff' fontSize='14px' fontWeight='700' pb='10px'>Мы в социальных сетях</Heading>
        <Flex gap={'10px'}>
            {
              socialData.map((el,index)=>(
                <a key={index} m={'4px 0'} href={el.link} target='_blank' cursor={'pointer'}>
                  {el.icon}
                </a>
              ))
            }
          </Flex>
        </Box>
          <Text textAlign={'center'} color={'#fff'} fontSize={{lg: "14px", base:"10px"}}>© {new Date().getFullYear()} ОсОО "ЗЕРО ВЕСТ". Все права защищены</Text>
      </Container>
    </Box>
  );
};

export default Footer;
