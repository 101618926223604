import { Container, Box, Heading, Image, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import ContainersZero from '../../assets/images/ёмкости для жидкостей.jpg';
import Containers1 from '../../assets/images/01ёмкостидляжидкостей.webp';
import Containers2 from '../../assets/images/02ёмкостидляжидкостей.webp';
import Containers3 from '../../assets/images/03ёмкостидляжидкостей.webp';
import Containers4 from '../../assets/images/04ёмкостидляжидкостей.webp';
import Containers5 from '../../assets/images/05ёмкостидляжидкостей.webp';

const Containers = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,

    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      src: Containers1,
      type: 'image',
    },
    {
      src: Containers2,
      type: 'image',
    },
    {
      src: Containers3,
      type: 'image',
    },
    {
      src: Containers4,
      type: 'image',
    },
    {
      src: Containers5,
      type: 'image',
    },
  ];
  const handleClick = index => {
    new Fancybox(images, { startIndex: index });
  };

  return (
    <Box bg={'#fff'}>
      <Heading
        p={{ md: '100px 0 50px 100px ', base: '120px 0 50px 20px' }}
        ml={'10px'}
        fontSize="18px"
        fontWeight={'700'}
        lineHeight="normal"
      >
        ËМКОСТИ ДЛЯ ЖИДКОСТЕЙ
      </Heading>
      <Image
        src={ContainersZero}
        alt="los"
        objectFit={'contain'}
        bg={'#BADED4'}
      />

      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Text
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
          p={'80px 0'}
        >
        Ёмкости из полипропилена – отличный незаменимый резервуар для питьевой воды, пищевых и непищевых жидкостей. Свойства полипропилена позволяют изготовить ёмкости самой разнообразной формы и размеров. Такие ёмкости абсолютно герметичны и водонепроницаемы, имеют длительный срок службы, устойчивы к температурным колебаниям от -40 до +80 градусов Цельсия, устойчивы к коррозии, механическим и химическим воздействиям, легки в эксплуатации и экологически безопасны. <br />
          <br />
          Ёмкости для жидкостей Zero Waste изготавливаются из высококачественного российского пищевого полипропилена с применением современных технологий. В производстве используется самое современное и лучшее Швейцарское оборудование, что в разы увеличивает степень герметичности по сравнению изготовления на других оборудованиях. Благодаря этому значительно увеличивается срок службы. Наши Ёмкости прослужат Вам долго и надежно. Ёмкости отлично подходят в качестве резервуара питьевой воды для жилых домов, кафе, ресторанов, торговых центров, производственных предприятий и туристических мест.  <br />
          <br />
          Всегда готовы ответить на все Ваши вопросы. Звоните и пишите нам.
        </Text>
      </Container>
      <Box bg="#BADED4" p={{ md: '60px', base: '20px' }}>
        <Box display={{ md: 'block', base: 'none' }}>
          <Slider {...settings}>
            {images.map((el, index) => (
              <Image
                onClick={() => {
                  handleClick(index);
                }}
                cursor="pointer"
                src={el.src}
                alt="los"
                padding={'0 10px'}
                maxW={'1000px'}
                maxH={'300px'}
              />
            ))}
          </Slider>
        </Box>
      </Box>
      <Box
        display={{ md: 'none', base: 'flex' }}
        justifyContent={'center'}
        flexWrap={'wrap'}
        p="40px 0"
        alignItems={'center'}
        flexDirection="column"
        gap="35px 0 "
      >
        {images.map((el, index) => (
          <Box
            key={index}
            bg={'#BADED4'}
            borderRadius={'10px'}
            maxW={{ md: '476px' }}
            maxH={{ md: '403px' }}
            m={{ md: '0 50px', base: '0' }}
          >
            <Image src={el.src} w={'100%'} h={'200px'} rounded="10px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Containers;
