import { Box, Button, Flex, Image } from '@chakra-ui/react';
import React, { useState } from 'react';

import Btn from '../../assets/images/responsiveBtn.png';
import banner from '../../assets/images/banner.png';
import Logo from '../../assets/svg/Logo';
import navData from './NavData';
import socialData from '../Main/Hero/socialData';
import PhoneSvg from '../../assets/svg/PhoneSvg';

const Responsive = () => {
  const [modal, setModal] = useState(false);
  return (
    <Box>
      <a href={'tel:+996551031223'}>
        <Box
          pos="fixed"
          right="80px"
          top="18px"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          p="0"
          zIndex="30"
        >
          <PhoneSvg />
        </Box>
      </a>

      <Button
        pos="fixed"
        right="30px"
        top="5px"
        onClick={() => setModal(true)}
        bg="transparent"
        _hover={{ bg: 'transparent' }}
        p="0"
        zIndex="30"
      >
        <Image src={Btn} alt="btn" w="23px" h="20px" />
      </Button>
      <Box
        pos="fixed"
        top={modal ? '0' : '-800px'}
        transition="1s"
        bg="#fff"
        zIndex="90"
        left="0"
        h="533px"
        right="0"
      >
        <Box pos="absolute" left="30%" top="22px">
          <Logo />
        </Box>

        <Box pos="absolute" zIndex="10" top="75px" left="20%" right="20%">
          <Box
            display="flex"
            flexDirection="column"
            gap="15px"
            textAlign="center"
            fontSize="24px"
            color="#004737"
            m="20px 0"
          >
            {navData.map((el, index) => (
              <a
                onClick={() => setModal(false)}
                style={{
                  margin: '2px 0 ',
                  color: '#004737',
                }}
                href={el.link}
                key={index}
              >
                {el.name}
              </a>
            ))}
          </Box>
          <Flex gap={'10px'} ml={'-16px'}>
            {socialData.map((el, index) => (
              <a key={index} href={el.link} target='_blank'  m={'4px 0'} cursor={'pointer'}>
                {el.icon}
              </a>
            ))}
          </Flex>
        </Box>

        <Image src={banner} w="100%" h="533px" />
      </Box>

      <Box
        onClick={() => setModal(false)}
        pos="fixed"
        top={modal ? '-100%' : '100%'}
        transition="1s"
        right="0"
        bottom="0"
        left="0"
        bg="rgba(0, 0, 0, 0.4)"
        backdropFilter="blur(15px)"
        zIndex="5"
      />
    </Box>
  );
};

export default Responsive;
