import React from 'react';

const Polypropylene4 = () => {
  return (
    <svg width='88' height='89' viewBox='0 0 88 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2911_917)'>
        <path d='M9.22064 56.2529H16.1363V60.7472H9.22064' stroke='#004737' strokeWidth='1.55503' />
        <path d='M23.0519 56.2529H16.1362V60.7472H23.0519V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M29.9675 56.2529H23.0519V60.7472H29.9675V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M36.8754 56.2529H29.9597V60.7472H36.8754V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M43.7901 56.2529H36.8745V60.7472H43.7901V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M50.7058 56.2529H43.7901V60.7472H50.7058V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M57.6222 56.2529H50.7065V60.7472H57.6222V56.2529Z' stroke='#004737' strokeWidth='1.55503' />
        <path d='M64.5283 60.7472H57.6127V56.2529H64.5283' stroke='#004737' strokeWidth='1.55503' />
        <path d='M29.445 40.2002H23.4004L9.22064 56.2525H64.5546L78.7343 40.2002H47.9361' stroke='#004737'
              strokeWidth='1.55503' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M57.7954 29.0603L49.7388 21.0037C49.4426 20.7163 49.0507 20.5508 48.6326 20.5508C48.1971 20.5508 47.7878 20.725 47.4742 21.0385L29.105 39.4077C28.8002 39.7038 28.6347 40.1045 28.626 40.5313C28.6173 40.9581 28.7741 41.3674 29.0789 41.6636L30.2547 42.8481L23.5481 49.5547C23.3652 49.7376 23.3043 50.0076 23.4001 50.2515C23.4959 50.4867 23.731 50.6435 23.9836 50.6522L33.1377 50.8612H33.1464C33.3206 50.8612 33.4861 50.7915 33.6081 50.6783L35.8465 48.4312L37.1356 49.7202C37.423 50.0164 37.8149 50.1818 38.2417 50.1818C38.6772 50.1818 39.0866 50.0076 39.3914 49.6941L57.7693 31.3249C58.3964 30.6891 58.4051 29.6788 57.7954 29.0603ZM32.8851 49.5721L25.5253 49.3979L31.1693 43.7539L34.932 47.5253L32.8851 49.5721ZM56.8548 30.4104L38.4856 48.7883C38.3898 48.8754 38.294 48.8928 38.2417 48.8928C38.1808 48.8928 38.1111 48.8754 38.0414 48.8144L29.9847 40.7577C29.9238 40.6881 29.9064 40.6097 29.9151 40.5574C29.9151 40.4703 29.9499 40.3832 30.0196 40.3135L48.3887 21.9444C48.4584 21.8747 48.5455 21.8398 48.6326 21.8398C48.6849 21.8398 48.7633 21.8573 48.8242 21.9182L56.8809 29.9749C56.9941 30.0881 56.9854 30.2884 56.8548 30.4104Z'
              fill='#004737' stroke='#004737' strokeWidth='0.069702' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M50.0269 26.6573C49.4085 26.0389 48.3982 26.0563 47.7623 26.6834L45.8287 28.6257C44.8707 28.6867 43.9735 29.096 43.2942 29.7754C42.5887 30.4809 42.2054 31.3867 42.1358 32.31L35.9604 38.4853C35.3333 39.1211 35.3159 40.1315 35.9343 40.7499L37.9376 42.7619C38.2424 43.058 38.6431 43.2148 39.0437 43.2148C39.4618 43.2148 39.8799 43.0493 40.2022 42.727L46.3775 36.5517C47.3356 36.4907 48.224 36.0901 48.9121 35.402C49.6001 34.7139 50.0008 33.8255 50.0705 32.8674L52.0041 30.9251C52.6399 30.298 52.6486 29.2789 52.0302 28.6693L50.0269 26.6573ZM39.2963 41.8212C39.2266 41.8822 39.1396 41.9257 39.0525 41.9257C39.0002 41.9257 38.9218 41.917 38.8521 41.8473L36.8401 39.8353C36.7269 39.7221 36.7443 39.5305 36.875 39.3998L42.3535 33.9126C42.5451 34.4613 42.8587 34.9665 43.2942 35.402C43.721 35.8288 44.2261 36.1423 44.7749 36.334L39.2963 41.8212ZM48.7901 32.5887C48.7901 33.3029 48.5114 33.9823 48.0062 34.4875C47.4923 34.9926 46.8217 35.2801 46.0988 35.2801C45.3845 35.2801 44.7052 34.9926 44.2 34.4875C43.1548 33.4423 43.1548 31.7351 44.2 30.69C44.7052 30.1848 45.3845 29.8974 46.0988 29.8974C46.8217 29.8974 47.4923 30.1848 48.0062 30.69C48.5114 31.1951 48.7901 31.8658 48.7901 32.5887ZM51.0982 30.0193L49.8527 31.2561C49.6524 30.7074 49.3388 30.2022 48.9121 29.7754C48.4853 29.3486 47.9801 29.0351 47.4314 28.8435L48.6769 27.5979C48.8075 27.4673 48.9991 27.4586 49.1124 27.5718L51.1244 29.5751C51.2376 29.6883 51.2202 29.8886 51.0982 30.0193Z'
              fill='#004737' stroke='#004737' strokeWidth='0.069702' />
        <path
          d='M46.0982 33.642C46.6803 33.642 47.1521 33.1701 47.1521 32.5881C47.1521 32.006 46.6803 31.5342 46.0982 31.5342C45.5162 31.5342 45.0443 32.006 45.0443 32.5881C45.0443 33.1701 45.5162 33.642 46.0982 33.642Z'
          fill='#004737' stroke='#004737' strokeWidth='0.069702' />
        <path d='M13.1404 56.3051L9.26447 60.6862H64.5984L78.7781 44.6426H74.6671' stroke='#004737'
              strokeWidth='1.55503' />
        <path d='M14.2463 50.0605H25.8827' stroke='#004737' strokeWidth='1.55503' />
        <path d='M57.8833 60.3988L61.5502 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M50.941 60.3988L54.6079 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M43.9559 60.3988L47.6227 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M37.1622 60.3988L40.8291 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M30.22 60.3988L33.8869 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M23.3568 60.3988L27.0237 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path d='M16.5108 60.3988L20.1777 56.2529' stroke='#004737' strokeWidth='1.55503' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.0523 1.22461C67.7955 1.22461 87.1141 20.5432 87.1141 44.3908C87.1141 68.134 67.7955 87.4526 44.0523 87.4526C20.2047 87.4526 0.886108 68.134 0.886108 44.3908C0.886108 20.5432 20.2047 1.22461 44.0523 1.22461Z'
              stroke='#004737' strokeWidth='1.82945' />
      </g>
      <defs>
        <clipPath id='clip0_2911_917'>
          <rect width='87.9699' height='87.9699' fill='white' transform='translate(0.0150146 0.353516)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Polypropylene4;
