import { Container, Box, Heading, Image, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import GreaseZero from '../../assets/images/жироуловитель.png';
import Grease1 from '../../assets/images/01жироуловитель.webp';
import Grease2 from '../../assets/images/02жироуловитель.webp';
import Grease3 from '../../assets/images/03жироуловитель.webp';
import Grease4 from '../../assets/images/04жироуловитель.webp';
import Grease5 from '../../assets/images/05жироуловитель.webp';

const GreaseTreaps = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,

    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const images = [
    {
      src: Grease1,
      type: 'image',
    },
    {
      src: Grease2,
      type: 'image',
    },
    {
      src: Grease3,
      type: 'image',
    },
    {
      src: Grease4,
      type: 'image',
    },
    {
      src: Grease5,
      type: 'image',
    },
  ];
  const handleClick = index => {
    new Fancybox(images, { startIndex: index });
  };

  return (
    <Box bg={'#fff'}>
      <Heading
        p={{ md: '100px 0 50px 100px ', base: '120px 0 50px 20px' }}
        ml={'10px'}
        fontSize="18px"
        fontWeight={'700'}
        lineHeight="normal"
      >
        ЖИРОУЛОВИТЕЛЬ
      </Heading>
      <Image src={GreaseZero} alt="los" objectFit={'contain'} bg={'#BADED4'} />

      <Container
        maxW={{ md: '67%', base: '95%' }}
        m={'auto'}
        pb={{ md: '100px', base: '50px' }}
      >
        <Text
          fontSize={'16px'}
          color="#000000"
          textAlign="justify"
          p={'80px 0'}
        >
          Жироуловитель – это специальный резервуар, выполненный из пищевого
          полипропилена и предназначенный для удаления жиров растительного и
          животного происхождения, содержащихся в стоках до попадания в
          канализационные трубы, что позволяет значительно уменьшить их
          засорение.
          <br />
          <br />
          Жироуловитель имеет небольшие размеры и устанавливается под мойкой до
          попадания стока в трубопровод канализации. Он не требует расходных
          материалов, очень легок в обслуживании. Требуется лишь раз в две
          недели открыть крышку и убрать накопивший жир и крупные отходы с
          определенного отсека. Он продолжает работать дальше.
          <br />
          <br />
          Жироуловитель Zero Waste необходим и незаменим для жилых домов с
          большим количеством жителей. Очень рекомендуется для кафе, ресторанов,
          гостиниц, пансионатов. То есть там, где готовится еда для большого
          количества людей. Мы изготавливаем Жироуловители любой формы и
          размеров.
          <br />
          <br />
          Всегда готовы ответить на все Ваши вопросы. Звоните и пишите нам.
        </Text>
      </Container>
      <Box bg="#BADED4" p={{ md: '60px', base: '20px' }}>
        <Box display={{ md: 'block', base: 'none' }}>
          <Slider {...settings}>
            {images.map((el, index) => (
              <Image
                onClick={() => {
                  handleClick(index);
                }}
                cursor="pointer"
                src={el.src}
                alt="los"
                padding={'0 10px'}
                maxW={'1000px'}
                maxH={'300px'}
              />
            ))}
          </Slider>
        </Box>
      </Box>
      <Box
        display={{ md: 'none', base: 'flex' }}
        justifyContent={'center'}
        flexWrap={'wrap'}
        p="40px 0"
        alignItems={'center'}
        flexDirection="column"
        gap="35px 0 "
      >
        {images.map((el, index) => (
          <Box
            key={index}
            bg={'#BADED4'}
            borderRadius={'10px'}
            maxW={{ md: '476px' }}
            maxH={{ md: '403px' }}
            m={{ md: '0 50px', base: '0' }}
          >
            <Image src={el.src} w={'100%'} h={'200px'} rounded="10px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GreaseTreaps;
