import React from 'react';

const MapSvg = () => {
  return (
    <>
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2927_2928)">
          <path
            d="M17.8595 10.083C17.8595 16.7913 10.3595 22.5413 10.3595 22.5413C10.3595 22.5413 2.8595 16.7913 2.8595 10.083C2.8595 7.79552 3.64967 5.60171 5.0562 3.98421C6.46272 2.36671 8.37037 1.45801 10.3595 1.45801C12.3486 1.45801 14.2563 2.36671 15.6628 3.98421C17.0693 5.60171 17.8595 7.79552 17.8595 10.083Z"
            stroke="#0E6B56"
            strokeWidth="1.35537"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.3595 12.958C11.7402 12.958 12.8595 11.6708 12.8595 10.083C12.8595 8.49519 11.7402 7.20801 10.3595 7.20801C8.97879 7.20801 7.8595 8.49519 7.8595 10.083C7.8595 11.6708 8.97879 12.958 10.3595 12.958Z"
            stroke="#0E6B56"
            strokeWidth="1.35537"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2927_2928">
            <rect
              width="20"
              height="23"
              fill="white"
              transform="translate(0.359497 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default MapSvg;
