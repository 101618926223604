import React from 'react';
import { Box, Container, Flex, Heading, Text, Image } from '@chakra-ui/react';
import Icon from '../../../assets/svg/icon';
import worksData from './WorksData';
import './Works.css';

import 'swiper/css';
import Map from './Map';

const Works = () => {


  return (
    <Box id={'works'} p={{ md: '100px 0', base: '50px 0' }} bg={'#BADED4'}>
      <Container maxW={'90%'} m={'auto'}>
        <Flex
          alignItems={'center'}
          ml={{ md: '140px', base: '0' }}
          pb={{ md: '50px', base: '0' }}
        >
          <Icon />
          <Heading
            ml={'10px'}
            fontSize={{ md: '46px', base: '25px' }}
            fontWeight={'700'}
          >
            Наши работы
          </Heading>
        </Flex>
        <Box
          display="flex"
          justifyContent={'center'}
          m={'auto'}
          alignItems={'center'}
          flexWrap={'wrap'}
        >
          {worksData.map((el, index) => {
            return (
              <Map
              key={index}
                image={el.image}
                text={el.text}
                desc={el.desc}
                perf={el.perf}
                place={el.place}
                index={index}
              />
            );
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default Works;
