import React from 'react';

const Telegram = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2837_3391)">
        <rect x="4" width="30" height="30" rx="15" fill="white"/>
        <path d="M25 9.45167L22.7459 21.2192C22.7459 21.2192 22.4306 22.0351 21.5642 21.6438L16.3634 17.5144L16.3393 17.5023C17.0418 16.849 22.4893 11.777 22.7274 11.5471C23.0959 11.191 22.8671 10.979 22.4392 11.248L14.3926 16.5397L11.2882 15.4581C11.2882 15.4581 10.7997 15.2781 10.7527 14.8868C10.7051 14.4949 11.3043 14.2829 11.3043 14.2829L23.9599 9.14171C23.9599 9.14171 25 8.66845 25 9.45167Z" fill="#0E6B56"/>
      </g>
      <defs>
        <filter id="filter0_d_2837_3391" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"  colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2837_3391"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2837_3391" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
};

export default Telegram;
