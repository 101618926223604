import React from 'react';

const Tiktok = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='4' width='30' height='30' rx='15' fill='white' />
        <path
          d='M26.2322 10.9402C25.1872 10.9402 24.185 10.525 23.4461 9.78609C22.7072 9.04716 22.2921 8.04498 22.2921 7H19.4771V18.023C19.4766 18.5256 19.3179 19.0153 19.0236 19.4226C18.7292 19.8299 18.3141 20.1343 17.8371 20.2926C17.3601 20.4508 16.8454 20.4549 16.3659 20.3043C15.8865 20.1537 15.4666 19.856 15.1657 19.4534C14.8649 19.0508 14.6984 18.5637 14.6899 18.0612C14.6814 17.5587 14.8312 17.0663 15.1181 16.6537C15.4051 16.2411 15.8146 15.9293 16.2887 15.7625C16.7628 15.5957 17.2773 15.5823 17.7594 15.7242V12.9878C16.7349 12.8496 15.6926 13.0273 14.7716 13.4972C13.8507 13.967 13.0951 14.7066 12.6056 15.6172C12.1161 16.5278 11.916 17.5661 12.0322 18.5934C12.1483 19.6206 12.575 20.5881 13.2554 21.3664C13.9358 22.1448 14.8375 22.697 15.8401 22.9495C16.8426 23.2019 17.8983 23.1424 18.8662 22.7791C19.834 22.4157 20.668 21.7657 21.2568 20.9159C21.8455 20.0661 22.161 19.0569 22.161 18.023L22.2431 12.4512C23.3997 13.3003 24.7976 13.7572 26.2324 13.755L26.2322 10.9402Z'
          fill='#0E6B56' />

      <defs>
        <filter id='filter0_d_2837_3397' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2837_3397' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2837_3397' result='shape' />
        </filter>
      </defs>
    </svg>

  );
};

export default Tiktok;
