import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Box } from '@chakra-ui/react';

const Layout = ({ children }) => {
  return (
    <Box className="layout">
      <Header />
      <main className="main">{children}</main>
      <Footer />
    </Box>
  );
};

export default Layout;
