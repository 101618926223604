import React from 'react';
import { Box, Container, Flex, Heading, Text, Image } from '@chakra-ui/react';
import Icon from '../../../assets/svg/icon';
import servicesData from './ServicesData';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Pagination} from 'swiper/modules';
import { Link } from 'react-router-dom';
import '../../../styles/swiper.css'
import 'swiper/css';
import 'swiper/css/pagination';

const Services = () => {
  return (
    <Box id={'services'} p={{ md: '100px 0 110px 0', base: '40px 0' }}>
      <Container maxW="100%" m={'auto'}>
        <Flex
          alignItems={'center'}
          pb={'50px'}
          justifyContent="start"
          ml={{ lg: '140px', base: '0' }}
        >
          <Icon />
          <Heading
            ml={'10px'}
            fontSize={{ md: '46px', base: '25px' }}
            fontWeight={'700'}
            lineHeight="normal"
          >
            Наши услуги
          </Heading>
        </Flex>

        <Box display={{ md: 'block', base: 'none' }} ml={'50px'} >
          <Swiper  modules={[ Pagination]} pagination={{ clickable: true }} spaceBetween={'1px'} slidesPerView={2.5} loop={false}>
            {servicesData.map((el, index) => (
              <SwiperSlide key={index} >
                <Link to={el.link} onClick={() => window.scroll(0, 0)}>
                  <Box
                    key={index}
                    bg={'#BADED4'}
                    borderRadius={'10px'}
                    w={{ md: '476px' }}
                    h={{ md: '570px' }}
                    m={{ md: '0 50px', base: '0' }}
                  >
                    <Image
                      src={el.image}
                      h={'75%'}
                      w={'100%'}
                      rounded={'10px 10px 0 0'}
                    />
                    <Box
                      p={'20px 20px'}
                      bg={'#BADED4'}
                      rounded={'0 0 10px 10px'}
                    >
                      <Heading color={'#0E6B56'} mb={'10px'} fontSize="18px">
                        {el.title}
                      </Heading>
                      <Text color={'#0E6B56'} fontSize="15px" bg={'#BADED4'}>
                        {el.desc}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Box
          display={{ md: 'none', base: 'flex' }}
          justifyContent={'center'}
          flexWrap={'wrap'}
          alignItems={'center'}
          flexDirection={{ lg: 'row', base: 'column' }}
          gap={{ md: '150px 0 ', base: '70px 0 ' }}
        >
          {servicesData.map((el, index) => (
            <Link to={el.link} onClick={() => window.scroll(0, 0)}>
              <Box
                key={index}
                bg={'#BADED4'}
                borderRadius={'10px'}
                maxW={{ md: '476px' }}
                maxH={{ md: '403px' }}
                m={{ md: '0 50px', base: '0' }}
              >
                <Image
                  src={el.image}
                  h={'100%'}
                  w={'100%'}
                  rounded={'10px 10px 0 0'}
                />
                <Box p={'20px 20px'} bg={'#BADED4'} rounded={'0 0 10px 10px'}>
                  <Heading color={'#0E6B56'} mb={'10px'} fontSize="18px">
                    {el.title}
                  </Heading>
                  <Text color={'#0E6B56'} fontSize="15px" bg={'#BADED4'}>
                    {el.desc}
                  </Text>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Services;
