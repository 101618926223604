import Polypropylene1 from '../../../assets/svg/polypropylene1';
import Polypropylene2 from '../../../assets/svg/polypropylene2';
import Polypropylene3 from '../../../assets/svg/polypropylene3';
import Polypropylene4 from '../../../assets/svg/polypropylene4';
import Polypropylene5 from '../../../assets/svg/polypropylene5';
import Polypropylene6 from '../../../assets/svg/polypropylene6';
import Polypropylene7 from '../../../assets/svg/polypropylene7';

const polypropyleneData = [
  {
    icon:<Polypropylene1/>,
    desc:'Для изделий характерен длительный срок эксплуатации'
  },
  {
    icon:<Polypropylene2/>,
    desc:'Возможность сборки любых размеров внутри помещения'
  },
  {
    icon:<Polypropylene3/>,
    desc:'Легкость и простота монтажа'
  },
  {
    icon:<Polypropylene4/>,
    desc:'Идеальная герметичность готовых изделий'
  },  {
    icon:<Polypropylene5/>,
    desc:'Высочайшая надежность'
  },
  {
    icon:<Polypropylene6/>,
    desc:'Возможность изготовления любой конфигурации и цветовой гаммы'
  },
  {
    icon:<Polypropylene7/>,
    desc:'Материалы пригодны для контакта с пищевыми продуктами'
  },



]
export default polypropyleneData
