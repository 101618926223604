import React from 'react';

const Polypropylene5 = () => {
  return (
    <svg width='89' height='89' viewBox='0 0 89 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2911_946)'>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.1215 27.541C43.6326 27.541 43.2395 27.9249 43.2395 28.4024C43.2395 28.8424 43.5846 29.2169 44.0352 29.2544C44.5529 29.3105 45.0035 28.9079 45.0035 28.4024C45.0035 27.9249 44.6104 27.541 44.1215 27.541Z'
              fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M61.7126 29.2642C59.2872 29.2642 57.3123 27.3355 57.3123 24.9668C57.3123 24.4893 56.9192 24.1055 56.4303 24.1055H31.8115C31.3226 24.1055 30.9295 24.4893 30.9295 24.9668C30.9295 27.3355 28.9546 29.2642 26.5292 29.2642C26.0403 29.2642 25.6472 29.6481 25.6472 30.1255V44.1412C25.6472 55.0672 32.9427 64.851 43.8716 68.053C43.9483 68.0717 44.0346 68.0904 44.1209 68.0904C44.2072 68.0904 44.2935 68.0717 44.3702 68.053C55.0594 64.9165 62.5946 55.3013 62.5946 44.1412V30.1162C62.5946 29.6481 62.2015 29.2642 61.7126 29.2642ZM60.8306 44.1412C60.8306 54.4025 54.1295 63.2875 44.1209 66.3303C34.189 63.3062 27.4112 54.3463 27.4112 44.1412V30.9214C30.1051 30.5375 32.2333 28.459 32.6264 25.8282H55.6154C56.0085 28.459 58.1367 30.5375 60.8306 30.9214V44.1412Z'
              fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M58.5494 32.0537C56.7088 31.2673 55.2612 29.8535 54.4559 28.056C54.3121 27.747 54.0053 27.541 53.6506 27.541H47.6397C47.1508 27.541 46.7577 27.9249 46.7577 28.4024C46.7577 28.8799 47.1508 29.2637 47.6397 29.2637H53.0946C54.0245 31.0332 55.4913 32.475 57.3127 33.3832V44.1407C57.3127 52.5482 52.1455 59.7948 44.1214 62.6972C36.0972 59.7948 30.93 52.5482 30.93 44.1407V33.3832C32.7515 32.475 34.2182 31.0332 35.1482 29.2637H40.603C41.0919 29.2637 41.485 28.8799 41.485 28.4024C41.485 27.9249 41.0919 27.541 40.603 27.541H34.5921C34.2374 27.541 33.9306 27.747 33.7868 28.056C32.9815 29.8535 31.5339 31.2673 29.6933 32.0537C29.3769 32.1848 29.166 32.4938 29.166 32.8402V44.1407C29.166 53.344 35.0044 61.377 43.8242 64.4198C43.92 64.4573 44.0255 64.476 44.1214 64.476C44.2172 64.476 44.3227 64.4573 44.4185 64.4198C53.2384 61.377 59.0767 53.3534 59.0767 44.1407V32.8402C59.0767 32.4938 58.8658 32.1848 58.5494 32.0537Z'
              fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.1231 36.6514C39.7612 36.6514 36.2045 40.1155 36.2045 44.3754C36.2045 48.6447 39.7612 52.1088 44.1231 52.1088C48.4851 52.1088 52.0418 48.6447 52.0418 44.3754C52.0418 40.1155 48.4851 36.6514 44.1231 36.6514ZM44.1231 50.3955C40.7294 50.3955 37.9684 47.6991 37.9684 44.3754C37.9684 41.0611 40.7294 38.3647 44.1231 38.3647C47.5168 38.3647 50.2778 41.0611 50.2778 44.3754C50.2778 47.6991 47.5168 50.3955 44.1231 50.3955Z'
              fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M48.2635 42.0536C47.9184 41.7165 47.3624 41.7165 47.0172 42.0536L43.2401 45.7424L42.1088 44.6283C41.7637 44.3006 41.2077 44.3006 40.8625 44.6283C40.5174 44.9653 40.5174 45.5083 40.8625 45.8454L42.6169 47.5681C42.7895 47.7366 43.0196 47.8115 43.2401 47.8115C43.4701 47.8115 43.6906 47.7366 43.8632 47.5681L48.2635 43.2707C48.6086 42.9336 48.6086 42.3906 48.2635 42.0536Z'
              fill='#004737' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M44.175 1.22461C67.9182 1.22461 87.2367 20.5432 87.2367 44.3908C87.2367 68.134 67.9182 87.4526 44.175 87.4526C20.3273 87.4526 1.00879 68.134 1.00879 44.3908C1.00879 20.5432 20.3273 1.22461 44.175 1.22461Z'
              stroke='#004737' strokeWidth='1.74198' />
      </g>
      <defs>
        <clipPath id='clip0_2911_946'>
          <rect width='87.9699' height='87.9699' fill='white' transform='translate(0.137695 0.353516)' />
        </clipPath>
      </defs>
    </svg>

  );
};

export default Polypropylene5;
