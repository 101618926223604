import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

const ModalForm = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };

  const handleSubmit = () => {
    const message = `Здравствуйте🙂!\n\nМеня зовут ${name}\nмой номер ${phone}\n\nПерезвоните мне \n\nСпасибо 😊`;
    const phoneNumber = '996999001199';
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;

    window.open(whatsappLink);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay bg="rgba(0, 0, 0, 0.6)" />
      <ModalContent
        m={'200px auto'}
        p={'50px 0'}
        alignItems={'center'}
        bg={'#fff'}
        justifyContent={'center'}
      >
        <ModalHeader textAlign={'center'}>Оставить заявку</ModalHeader>
        <ModalCloseButton
          bg="rgba(105, 136, 116, 1)"
          pos={'absolute'}
          top={'20px'}
          right={'20px'}
        />
        <ModalBody pb={6}>
          <FormControl m={'20px 0'}>
            <FormLabel color={'rgba(84, 153, 32, 1)'}>Имя*</FormLabel>
            <Input
              type="text"
              placeholder="Полное имя"
              value={name}
              onChange={handleNameChange}
              border={'none'}
              borderBottom={'1px solid rgba(84, 153, 32, 1)'}
              borderRadius={'0'}
              _focusVisible={{ boxShadow: 'none' }}
              mt={'10px 0'}
            />
          </FormControl>

          <FormControl m={'20px 0'}>
            <FormLabel color={'rgba(84, 153, 32, 1)'}>Телефон*</FormLabel>
            <Input
              type="phone"
              placeholder="Номер телефона"
              value={phone}
              onChange={handlePhoneChange}
              border={'none'}
              borderBottom={'1px solid rgba(84, 153, 32, 1)'}
              borderRadius={'0'}
              _focusVisible={{ boxShadow: 'none' }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'center'}>
          <Button
            bg="rgba(105, 136, 116, 1)"
            color="#fff"
            mr={3}
            onClick={handleSubmit}
          >
            Отправить
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalForm;
