import { Box, Button, Container, Flex, Link } from '@chakra-ui/react';
import Logo from '../../assets/svg/Logo';
import navData from './NavData';
import Responsive from './Responsive';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <Box
        bg={'#fff'}
        id={'header'}
        pos={'fixed'}
        zIndex="30"
        w={'100%'}
        top={'0'}
        p={{ lg: '10px 0', base: '7px 0' }}
      >
        <Container maxW={'90%'} m={'auto'}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
  <Box onClick={() => navigate("/#")}>
   <Logo />
  </Box>
        
            <Box display={{ lg: 'flex', base: 'none' }}>
              {navData.map((el, index) => (
                <Box
                key={index}
                  onClick={() => navigate(el.link)}
                  m={{ md: '0 15px', lg: '0 3px', xl: '0 15px', sm: '0 2px' }}
                  _hover={{ textDecoration: 'none' }}
                  color={'#000'}
                >
                  <a href={el.link} >
                    {el.name}
                  </a>
                </Box>
              ))}
            </Box>
            <Link
              href={'tel:+996999001199'}
              display={{ lg: 'block', base: 'none' }}
            >
              <Button
                bg={'#428875'}
                borderRadius={'50px'}
                color="#FFF"
                _hover={{ bg: '#428875' }}
                fontSize="14px"
                p="14px 32px"
              >
                Позвонить
              </Button>
            </Link>
          </Flex>
        </Container>
      </Box>
      <Box display={{ lg: 'none', base: 'block' }}>
        <Responsive />
      </Box>
    </Box>
  );
};

export default Header;
