import Whatsapp from '../../../assets/svg/whatsapp';
import Telegram from '../../../assets/svg/telegram';
import Instagram from '../../../assets/svg/instagram';
import Tiktok from '../../../assets/svg/Tiktok';
import Youtube from '../../../assets/svg/Youtube';
import Facebook from '../../../assets/svg/Facebook';

const socialData = [
  {
    icon: <Whatsapp/>,
    link: 'https://wa.me/999001199',
  },
  {
    icon: <Telegram/>,
    link: 'https://t.me/+996999001199',
  },
  {
    icon: <Instagram/>,
    link: 'https://www.instagram.com/zerowaste_kgz/',
  },

  {
    icon: <Tiktok/>,
    link: 'https://www.tiktok.com/@zero_waste.kgz',
  },
  {
    icon: <Youtube/>,
    link: 'https://www.youtube.com/@user-uc1jx7jx6i',
  },
  {
    icon: <Facebook/>,
    link: 'https://www.facebook.com/profile.php?id=100066937185076',
  },

]
export default socialData
