import React from 'react';

const EmailSvg = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.95327 3.95312H19.7659C20.8531 3.95312 21.7425 4.84259 21.7425 5.92971V17.7892C21.7425 18.8763 20.8531 19.7658 19.7659 19.7658H3.95327C2.86615 19.7658 1.97668 18.8763 1.97668 17.7892V5.92971C1.97668 4.84259 2.86615 3.95312 3.95327 3.95312Z"
          stroke="#0E6B56"
          strokeWidth="1.35537"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7425 5.92969L11.8596 12.8477L1.97668 5.92969"
          stroke="#0E6B56"
          strokeWidth="1.35537"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default EmailSvg;
