import React from 'react';

const Whatsapp = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' width='30' height='30' rx='15' fill='white' />
      <path
        d='M24.9451 9.04687C23.4634 7.57031 21.4878 6.75 19.4024 6.75C15.0671 6.75 11.5549 10.25 11.5549 14.5703C11.5549 15.9375 11.939 17.3047 12.5976 18.4531L11.5 22.5L15.6707 21.4062C16.8232 22.0078 18.0854 22.3359 19.4024 22.3359C23.7378 22.3359 27.25 18.8359 27.25 14.5156C27.1951 12.4922 26.4268 10.5234 24.9451 9.04687ZM23.189 17.3594C23.0244 17.7969 22.2561 18.2344 21.872 18.2891C21.5427 18.3437 21.1037 18.3438 20.6646 18.2344C20.3902 18.125 20.0061 18.0156 19.5671 17.7969C17.5915 16.9766 16.3293 15.0078 16.2195 14.8438C16.1098 14.7344 15.3963 13.8047 15.3963 12.8203C15.3963 11.8359 15.8902 11.3984 16.0549 11.1797C16.2195 10.9609 16.439 10.9609 16.6037 10.9609C16.7134 10.9609 16.878 10.9609 16.9878 10.9609C17.0976 10.9609 17.2622 10.9062 17.4268 11.2891C17.5915 11.6719 17.9756 12.6562 18.0305 12.7109C18.0854 12.8203 18.0854 12.9297 18.0305 13.0391C17.9756 13.1484 17.9207 13.2578 17.811 13.3672C17.7012 13.4766 17.5915 13.6406 17.5366 13.6953C17.4268 13.8047 17.3171 13.9141 17.4268 14.0781C17.5366 14.2969 17.9207 14.8984 18.5244 15.4453C19.2927 16.1016 19.8963 16.3203 20.1159 16.4297C20.3354 16.5391 20.4451 16.4844 20.5549 16.375C20.6646 16.2656 21.0488 15.8281 21.1585 15.6094C21.2683 15.3906 21.4329 15.4453 21.5976 15.5C21.7622 15.5547 22.75 16.0469 22.9146 16.1562C23.1341 16.2656 23.2439 16.3203 23.2988 16.375C23.3537 16.5391 23.3537 16.9219 23.189 17.3594Z'
        fill='#0E6B56'
      />
      <defs>
        <filter id='filter0_d_2837_3388' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'>
          <feFlood result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2837_3388' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2837_3388' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};

export default Whatsapp;
