import React from 'react';

const Facebook = () => {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' width='30' height='30' rx='15' fill='white' />
      <path
        d='M20.2428 15.2736H23.0344L23.4728 12.4204H20.2422V10.861C20.2422 9.67574 20.6272 8.62471 21.7292 8.62471H23.5V6.13481C23.1889 6.09254 22.5308 6 21.2875 6C18.6911 6 17.169 7.37947 17.169 10.5223V12.4204H14.5V15.2736H17.169V23.1158C17.6976 23.1957 18.233 23.25 18.7826 23.25C19.2793 23.25 19.7642 23.2043 20.2428 23.1392V15.2736Z'
        fill='#0E6B56' />

      <defs>
        <filter id='filter0_d_2837_3403' x='0' y='0' width='38' height='38' filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2837_3403' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2837_3403' result='shape' />
        </filter>
      </defs>
    </svg>

  );
};

export default Facebook;
