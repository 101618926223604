import React from 'react';
import { Box, Container, Flex, Heading, Text, Image } from '@chakra-ui/react';
import Icon from '../../../assets/svg/icon';
import AboutImg from '../../../assets/images/about.webp';
const About = () => {
  return (
    <Box bg={'#BADED4'} p={{md:"100px 0", base:"50px 0"}} id={'about'}>
      <Container maxW={{ md: '90%', base: '100%' }} m={'auto'}>
        <Flex
          alignItems={'center'}
          ml={{ md: '140px', sm: '90px', base: '0' }}
          mb={{md:"71px", base:"51px"}}
        >
          <Icon />
          <Heading
            ml={'10px'}
            fontSize={{ md: '46px', base: '25px' }}
            fontWeight={'700'}
          >
            О нас
          </Heading>
        </Flex>
        <Box
          display={'flex'}
          justifyContent={{ lg: 'center', base: 'space-between' }}
          flexDirection={{ md: 'row', base: 'column' }}
          gap={{ md: '20px', base: '20px' }}
          alignItems={'center'}
          mb={{md:"100px", base:"0"}}
        >
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Box
              w={'2px'}
              h={{ lg: '390px', base: '390px', md: '300px', sm: '300px' }}
              bg={'#0E6B56'}
            />
            <Text
              w={{ lg: '500px', base: '100%' }}
              fontSize={{ lg: '20px', base: '15px', md: '20px' }}
              fontWeight={'400'}
              fontFamily={'Open Sans, sans-serif'}
              ml={'20px'}
              lineHeight="normal"
            >
              Наша компания с 2020 года занимается производством и установкой
              комплексных инженерных сооружении в Кыргызстане, а также в странах
              СНГ и Персидского Залива. В производстве используется только
              высококачественный российский пищевой полипропилен, немецкие и
              японские комплектующие и швейцарские инструменты.
              <br />
              <br />
              Наши специалисты имеют многолетний опыт работы в зарубежных
              крупных компаниях и используют самую современную технологию в
              своей отрасли. Их высокая квалификация отмечена зарубежными
              сертификатами.
            </Text>
          </Flex>

          <Image
            src={AboutImg}
            alt={'about'}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default About;
